import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _sliced_to_array from "@swc/helpers/src/_sliced_to_array.mjs";
import { isBrowser, isMobile } from "@cinch-labs/shared-util";
export function cleanPageName(str) {
    return str.replace(/[/-]/g, function(substring) {
        for(var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++){
            args[_key - 1] = arguments[_key];
        }
        var _args = _sliced_to_array(args, 2), position = _args[0], original = _args[1];
        if (position === 0 || position === original.length - 1) {
            return "";
        }
        if (substring === "/") {
            return ":";
        }
        if (substring === "-") {
            return "_";
        }
        return substring;
    });
}
export var rewritePageName = function(name) {
    if (name === "") {
        return "homepage";
    }
    if (name.split(":")[0] === "vehicle") {
        return "vehicle_details";
    }
    if (name.split(":")[0] === "dealers") {
        if (name.split(":")[1] === "vehicle") {
            return "vehicle_details";
        }
        if (name.split(":")[1] === "find_vehicle") {
            return "find_vehicle";
        }
    }
    if (name.split(":")[0] === "direct" && name.split(":")[1] === "vehicle") {
        return "vehicle_details";
    }
    if (name.split(":")[0] === "used_cars") {
        if (name.split(":")[3] === "details") {
            return "vehicle_details";
        }
        return "find_vehicle";
    }
    return name;
};
/**
 * This method should update the data layer with location information for the pageInfo object
 */ export function updatePageInfo(param) {
    var location = param.location, prevLocation = param.prevLocation;
    var oxfordRoutes = [
        "/dealers/find-vehicle",
        "/vehicle"
    ];
    var cambridgeRoutes = [
        "/find-vehicle",
        "/used-cars"
    ];
    if (isBrowser) {
        var dl = window.adobeData;
        if (dl && dl.page && dl.page.pageInfo) {
            dl.page.pageInfo.pageURL = location.href;
            dl.page.pageInfo.pageName = rewritePageName(cleanPageName(location.pathname));
            if (prevLocation) {
                dl.page.pageInfo.previousPageName = rewritePageName(cleanPageName(prevLocation.pathname || ""));
            }
            dl.page.pageInfo.project = "";
            if (cambridgeRoutes.some(function(x) {
                return location.pathname.startsWith(x);
            })) {
                dl.page.pageInfo.project = "cinch";
            } else if (oxfordRoutes.some(function(x) {
                return location.pathname.startsWith(x);
            })) {
                dl.page.pageInfo.project = "dealer";
            }
        }
    }
}
export function getSiteInfoForDigitalData() {
    var platformType = isMobile() ? "mobileWeb" : "desktopWeb";
    return {
        brandCode: "cinch",
        brandName: "cinch",
        language: "en-gb",
        country: "gb",
        platformType: platformType
    };
}
/* Expects a string in the following format:
  'cinch:direct:veh icle:1234'
  'cinch:used-cars:audi:a1:1234'
   generated by writeStandardPageInfoToDataLayer()
 */ export var pageNameContainsVehicleId = function(pageName) {
    var pageNameSplit = pageName.split(":");
    // Only the FPA and checkout journeys contain IDs in their paths - see gatsby-node.ts for the routes
    var deprecatedFPAPageName = pageNameSplit[1] === "direct" && pageNameSplit[2] === "vehicle";
    var currentFPAPageName = pageNameSplit[1] === "used-cars" && pageNameSplit[4] === "details";
    var checkoutPageName = pageNameSplit[1] === "checkout";
    return deprecatedFPAPageName || currentFPAPageName || checkoutPageName;
};
export function getStandardPageInfo(currentLocationPath, previousLocationPath) {
    var finalisedPageName;
    var eventValue = {};
    var transformedPageName = "cinch:".concat(currentLocationPath.replace(/^\/|\/$/g, "") // trim starting and ending slashes
    .replace(/\//g, ":") // replace remaining slashes with colons
    .replace(/(%20)/g, "-")) // replace spaces in url with hyphens
    ;
    finalisedPageName = pageNameContainsVehicleId(transformedPageName) ? transformedPageName.split(":").slice(0, -1).join(":") : transformedPageName;
    if (finalisedPageName.includes("used-cars") && finalisedPageName.includes("details")) {
        finalisedPageName = "cinch:direct:vehicle";
    }
    if (finalisedPageName.includes("track")) {
        eventValue = {
            value: finalisedPageName.split(":")[3]
        };
        finalisedPageName = "cinch:my_profile:orders:track";
    }
    if (finalisedPageName.includes("used-cars")) {
        finalisedPageName = "cinch:find_vehicle";
    }
    var payload = {
        event: _object_spread({
            name: "pageView"
        }, eventValue),
        page: {
            pageName: finalisedPageName,
            pagePath: currentLocationPath
        }
    };
    // Only post site module on first page load
    if (previousLocationPath === undefined) {
        payload.site = getSiteInfoForDigitalData();
    }
    if (previousLocationPath !== undefined) {
        payload.page.previousPage = previousLocationPath;
    }
    return payload;
}
export default updatePageInfo;
