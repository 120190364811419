export var BASKET_BANNER_CLOSED = "cinch-checkout-basket-banner-closed";
export var getBasketBannerClosed = function() {
    if ("object" !== "undefined") {
        var ref;
        return JSON.parse((ref = window.localStorage.getItem(BASKET_BANNER_CLOSED)) !== null && ref !== void 0 ? ref : "false");
    }
    return false;
};
export var setBasketBannerClosed = function(value) {
    if ("object" !== "undefined") {
        window.localStorage.setItem(BASKET_BANNER_CLOSED, value ? "true" : "false");
    }
};
export var clearBasketBannerClosed = function() {
    if ("object" !== "undefined") {
        window.localStorage.removeItem(BASKET_BANNER_CLOSED);
    }
};
