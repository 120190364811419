import { z } from "zod";
import { cv, numericString, slug, version } from "../../types/schema";
import { toUrlSearchParams } from "../../util/to-url-search-params";
export var getStoriesParams = z.object({
    starts_with: slug({
        displayName: "starts_with"
    }),
    version: version,
    cv: cv,
    sort_by: z.string().optional(),
    per_page: numericString().optional(),
    page: numericString().optional(),
    with_tag: z.string().optional()
});
export var getStoriesUrlParams = getStoriesParams.transform(toUrlSearchParams);
