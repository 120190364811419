import { z } from "zod";
import { cv, numericString, version } from "../../types/schema";
import { toUrlSearchParams } from "../../util/to-url-search-params";
export var getStoryBySlugParams = z.object({
    version: version,
    cv: cv,
    sort_by: z.string().optional(),
    per_page: numericString().optional(),
    page: numericString().optional(),
    with_tag: z.string().optional(),
    resolve_relations: z.string().optional()
}).optional();
export var getStoryBySlugUrlParams = getStoryBySlugParams.transform(toUrlSearchParams);
export { slug } from "../../types/schema";
