import { track } from "@cinch-labs/customer-data-segment";
export var cookieSettings = function(settings) {
    track("Cookie Settings", settings);
};
export var cookieBannerMetrics = function(settings) {
    track("Cookie Banner metrics", settings);
};
export var cookieEditorShown = function(timeStamp) {
    track("Cookie Editor Shown", {
        event_timing: timeStamp
    });
};
