import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
import { advertisingScriptList, essentialCinchScriptsList } from "../scripts-config";
function processToolNames(names, extras) {
    var ref, ref1;
    return names.filter(Boolean).map(function(dest) {
        return dest.name;
    })// eslint-disable-next-line unicorn/prefer-spread
    .concat((ref = extras === null || extras === void 0 ? void 0 : extras.include) !== null && ref !== void 0 ? ref : []).filter(function(name) {
        var ref;
        return (ref1 = !(extras === null || extras === void 0 ? void 0 : (ref = extras.remove) === null || ref === void 0 ? void 0 : ref.includes(name))) !== null && ref1 !== void 0 ? ref1 : true;
    });
}
// Setup preferences object which constructs the data in the cookie prefs modal
export var setupPreferences = function(destinations) {
    var ref;
    var functionalTools = processToolNames((ref = destinations === null || destinations === void 0 ? void 0 : destinations["Customer Success"]) !== null && ref !== void 0 ? ref : [], {
        include: [
            "Segment"
        ],
        remove: [
            "Zendesk"
        ]
    });
    var ref1, ref2, ref3;
    var marketingTools = processToolNames(_to_consumable_array((ref1 = destinations === null || destinations === void 0 ? void 0 : destinations["Analytics"]) !== null && ref1 !== void 0 ? ref1 : []).concat(_to_consumable_array((ref2 = destinations === null || destinations === void 0 ? void 0 : destinations["Email Marketing"]) !== null && ref2 !== void 0 ? ref2 : []), _to_consumable_array((ref3 = destinations === null || destinations === void 0 ? void 0 : destinations["Heatmaps & Recordings"]) !== null && ref3 !== void 0 ? ref3 : [])), {
        include: [
            "Hotjar",
            "TikTok",
            "Amazon"
        ],
        remove: [
            "Amplitude"
        ]
    });
    var ref4;
    var advertisingTools = processToolNames((ref4 = destinations === null || destinations === void 0 ? void 0 : destinations["Advertising"]) !== null && ref4 !== void 0 ? ref4 : []);
    var preferences = [
        {
            title: "Functional",
            category: "Customer Success",
            purpose: "To monitor the performance of our site and to enhance your browsing experience. For example, these tools enable you to communicate with us via live chat.",
            tools: functionalTools
        },
        {
            title: "Marketing and Analytics",
            category: "Analytics",
            purpose: "To understand user behavior in order to provide you with a more relevant browsing experience or personalize the content on our site. For example, we collect information about which pages you visit to help us present more relevant information.",
            tools: marketingTools
        },
        {
            title: "Advertising",
            category: "Advertising",
            purpose: "To personalize and measure the effectiveness of advertising on our site and other websites. For example, we may serve you a personalized ad based on the pages you visit on our site.",
            tools: advertisingTools
        },
        {
            title: "Essential",
            category: null,
            purpose: "We use browser cookies that are necessary for the site to work as intended. For example, we store your website data collection preferences so we can honor them if you return to our site, and track sales so that rewards/cashback sites can recognise a sale has occurred. You can disable these cookies in your browser settings but if you do the site may not work as intended.",
            tools: [
                essentialCinchScriptsList
            ]
        }, 
    ];
    // Adds the cinch script names (scripts-config.ts) into the modals category preferences
    advertisingScriptList.forEach(function(scriptName) {
        var ref, ref1, ref2, ref3;
        var name = scriptName.includes("MediaCom") ? "MediaCom" : scriptName;
        scriptName && (preferences === null || preferences === void 0 ? void 0 : (ref = preferences[2]) === null || ref === void 0 ? void 0 : (ref1 = ref.tools) === null || ref1 === void 0 ? void 0 : ref1.includes(name)) && (preferences === null || preferences === void 0 ? void 0 : (ref2 = preferences[2]) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.tools) === null || ref3 === void 0 ? void 0 : ref3.push(name));
    });
    return preferences;
};
