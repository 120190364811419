import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _instanceof from "@swc/helpers/src/_instanceof.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import TargetClient from "@adobe/target-nodejs-sdk";
import nookies from "nookies";
import { getActivityId, getActivityLocationId, timeoutPromise } from "../helpers";
import { targetClientOptions, CLIENT_TIMEOUT } from "./config";
import { TARGET_CLIENT_ERROR_MESSAGES as ERROR_MESSAGES } from "./errors";
var targetClient;
/**
 * Initialises the target client and awaits for this to become ready, then calls
 * the client to retrieve the specified target activity.
 *
 * @param ssrCtx The server-side context object from getServerSideProps
 * @param activityLocationId The activity location or mbox name
 * @param targetActivityConst The activity constant
 * @returns The target activity object or null
 */ export function getAdobeTargetSSR(ssrCtx, activityLocationConst, targetActivityConst) {
    return _getAdobeTargetSSR.apply(this, arguments);
}
function _getAdobeTargetSSR() {
    _getAdobeTargetSSR = _async_to_generator(function(ssrCtx, activityLocationConst, targetActivityConst) {
        var cookies, checkCookiesAccepted, ref, isClientReady, activityId, activityLocationId, targetCookie, targetActivity, error;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    cookies = nookies.get(ssrCtx);
                    checkCookiesAccepted = checkAnalyticCookieExists(cookies);
                    if (!checkCookiesAccepted) {
                        return [
                            2,
                            null
                        ];
                    }
                    // Initialise the client
                    targetClient = TargetClient.create(_object_spread_props(_object_spread({}, targetClientOptions), {
                        events: {
                            artifactDownloadFailed: function(event) {
                                return handleArtifactDownloadFailure(event);
                            }
                        }
                    }));
                    _state.label = 1;
                case 1:
                    _state.trys.push([
                        1,
                        6,
                        ,
                        7
                    ]);
                    // Wait for the client to complete it's initialisation before continuing
                    return [
                        4,
                        timeoutPromise(CLIENT_TIMEOUT, clientMonitor(), new Error(ERROR_MESSAGES.INIT_TIMEOUT))
                    ];
                case 2:
                    _state.sent();
                    isClientReady = (ref = targetClient.decisioningEngine) === null || ref === void 0 ? void 0 : ref.isReady();
                    if (!isClientReady) return [
                        3,
                        4
                    ];
                    activityId = getActivityId(targetActivityConst);
                    activityLocationId = getActivityLocationId(activityLocationConst);
                    targetCookie = cookies[TargetClient.TargetCookieName] // 'mbox'
                    ;
                    return [
                        4,
                        fetchTargetActivity(activityLocationId, targetCookie, activityId, ssrCtx)
                    ];
                case 3:
                    targetActivity = _state.sent();
                    return [
                        2,
                        targetActivity
                    ];
                case 4:
                    throw new Error(ERROR_MESSAGES.TARGET_CLIENT_DECISIONING_ENGINE_NOT_READY);
                case 5:
                    return [
                        3,
                        7
                    ];
                case 6:
                    error = _state.sent();
                    console.warn(error);
                    return [
                        2,
                        null
                    ];
                case 7:
                    return [
                        2
                    ];
            }
        });
    });
    return _getAdobeTargetSSR.apply(this, arguments);
}
/**
 * Calls the initialised target client to retrieve the target activity object if it exists
 * @param activityLocationId The activity location ID
 * @param targetCookie The target cookie (string or undefined)
 * @param activityId The activity id from the environments file
 * @param ssrCtx The server-side context object from getServerSideProps
 * @returns The target activity object or null
 */ export function fetchTargetActivity(activityLocationId, targetCookie, activityId, ssrCtx) {
    return _fetchTargetActivity.apply(this, arguments);
}
function _fetchTargetActivity() {
    _fetchTargetActivity = _async_to_generator(function(activityLocationId, targetCookie, activityId, ssrCtx) {
        var request, response, existingTargetCookie, newTargetCookie, targetActivity, error;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    _state.trys.push([
                        0,
                        2,
                        ,
                        3
                    ]);
                    request = {
                        execute: {
                            mboxes: [
                                {
                                    address: {
                                        url: "".concat(ssrCtx.req.headers.host).concat(ssrCtx.req.url)
                                    },
                                    name: activityLocationId
                                }
                            ]
                        }
                    };
                    return [
                        4,
                        targetClient.getOffers({
                            request: request,
                            targetCookie: targetCookie
                        })
                    ];
                case 1:
                    response = _state.sent();
                    if (!response) {
                        throw new Error(ERROR_MESSAGES.FAILED_TO_FETCH_TARGET_ACTIVITIES);
                    }
                    existingTargetCookie = targetCookie;
                    /**
     * If the cookie doesn't exist, it means the user has accepted the cookie banner
     * and the page has reloaded. However, Adobe scripts haven't loaded by this point (as
     * we're still in the server and scripts run client), so we want to get the targetCookie
     * returned from the get attributes response and set it before the client loads.
     * */ if (!existingTargetCookie) {
                        newTargetCookie = response.targetCookie;
                        nookies.set(ssrCtx, newTargetCookie.name, newTargetCookie.value, {
                            maxAge: newTargetCookie.maxAge
                        });
                    }
                    targetActivity = getTargetActivity(Number(activityId), response.responseTokens);
                    return [
                        2,
                        targetActivity
                    ];
                case 2:
                    error = _state.sent();
                    if (_instanceof(error, Error)) {
                        throw new TypeError(error.message);
                    }
                    return [
                        2,
                        null
                    ];
                case 3:
                    return [
                        2
                    ];
            }
        });
    });
    return _fetchTargetActivity.apply(this, arguments);
}
/**
 * @param activityId The activity ID for the experiment
 * @param response OfferAttributesGetResponse
 * @returns The target activity object or appropriate errors if not found
 */ export function getTargetActivity(activityId, allTargetActivities) {
    if (!allTargetActivities) {
        throw new Error(ERROR_MESSAGES.NO_TARGET_ACTIVITIES_FOUND);
    }
    var targetActivity = allTargetActivities.find(function(activity) {
        return activity["activity.id"] === Number(activityId);
    });
    if (!targetActivity) {
        throw new Error(ERROR_MESSAGES.TARGET_ACTIVITY_NOT_FOUND);
    }
    return targetActivity;
}
/**
 * Validates the correct cinch cookies (analytics) has been accepted
 * @param cookies
 * @returns boolean
 */ export function checkAnalyticCookieExists(cookies) {
    if (cookies["cp"]) {
        var parsedCinchCookies = JSON.parse(cookies["cp"]);
        if (parsedCinchCookies.analytics) return true;
    }
    return false;
}
/**
 * Monitors the target client initialisation. Will resolve the promise once the decisioning engine is ready.
 * @returns A promise that resolves when the property is available.
 */ function clientMonitor() {
    return new Promise(function(resolve) {
        var interval = setInterval(function() {
            var ref;
            var result = targetClient;
            if ((ref = result.decisioningEngine) === null || ref === void 0 ? void 0 : ref.isReady()) {
                clearInterval(interval);
                resolve();
            }
        }, 100);
    });
}
var handleArtifactDownloadFailure = function(event) {
    throw new Error("Artifact failed to download:\n".concat(event));
};
