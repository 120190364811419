import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _instanceof from "@swc/helpers/src/_instanceof.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import { STORYBLOK_BFF_URL } from "../../util/api";
import { getStoryBySlugUrlParams } from "./schema";
import { setTag, tagError, traceFunction } from "@core/datadog-server";
var hasStoryblokStory = function(input) {
    if (!input || !Object.prototype.hasOwnProperty.call(input, "story")) {
        return false;
    }
    return true;
};
function getStoryBySlugFn(slug, params) {
    return _getStoryBySlugFn.apply(this, arguments);
}
function _getStoryBySlugFn() {
    _getStoryBySlugFn = _async_to_generator(function(slug, params) {
        var // eslint-disable-next-line unicorn/no-object-as-default-parameter
        options, urlParams, url, request, ref, response, error, errorMessage;
        var _arguments = arguments;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    options = _arguments.length > 2 && _arguments[2] !== void 0 ? _arguments[2] : {
                        strict: true
                    };
                    urlParams = getStoryBySlugUrlParams.parse(params);
                    urlParams.append("slug", slug);
                    url = "".concat(STORYBLOK_BFF_URL, "/story-by-slug?").concat(urlParams.toString());
                    _state.label = 1;
                case 1:
                    _state.trys.push([
                        1,
                        4,
                        ,
                        5
                    ]);
                    return [
                        4,
                        fetch(url)
                    ];
                case 2:
                    request = _state.sent();
                    setTag("request.url", url);
                    setTag("request.params", JSON.stringify(Object.fromEntries(urlParams.entries()), null, 2));
                    setTag("request.method", "GET");
                    setTag("request.status", request.status);
                    setTag("request.content-length", (ref = request.headers.get("content-length")) !== null && ref !== void 0 ? ref : "0");
                    return [
                        4,
                        request.json()
                    ];
                case 3:
                    response = _state.sent();
                    if (!hasStoryblokStory(response)) {
                        throw new Error("Story Response has no story property.");
                    }
                    return [
                        2,
                        _object_spread_props(_object_spread({}, response.story), {
                            rels: response.rels
                        })
                    ];
                case 4:
                    error = _state.sent();
                    if (!options.strict) {
                        return [
                            2,
                            null
                        ];
                    }
                    if (_instanceof(error, Error) || typeof error === "string") {
                        tagError(error);
                    }
                    errorMessage = error || "Unknown Error";
                    if (_instanceof(error, Error)) {
                        errorMessage = error.message;
                    }
                    throw new Error("'story-by-slug' endpoint error\n        Original Error: \"".concat(errorMessage, '"\n        Slug: "').concat(slug, '",\n        Failing Url: "').concat(url, '"\n    '));
                case 5:
                    return [
                        2
                    ];
            }
        });
    });
    return _getStoryBySlugFn.apply(this, arguments);
}
export var getStoryBySlug = traceFunction("storyblok.getStoryBySlug", getStoryBySlugFn);
