export var getAnalyticIDs = function() {
    var ref, ref1, ref2;
    var adobeID = window === null || window === void 0 ? void 0 : (ref = window.s) === null || ref === void 0 ? void 0 : ref.marketingCloudVisitorID;
    var googleAnalyticObj = typeof (window === null || window === void 0 ? void 0 : (ref1 = window.ga) === null || ref1 === void 0 ? void 0 : ref1.getAll) === "function" && (window === null || window === void 0 ? void 0 : (ref2 = window.ga) === null || ref2 === void 0 ? void 0 : ref2.getAll());
    var gaID = Array.isArray(googleAnalyticObj) && googleAnalyticObj.length > 0 && googleAnalyticObj[0].get("clientId") || undefined;
    return {
        adobeID: adobeID,
        gaID: gaID
    };
};
