export var popularMakes = [
    {
        heading: "Popular makes",
        navItems: [
            {
                type: "link",
                label: "Ford",
                href: "/used-cars/ford"
            },
            {
                type: "link",
                label: "BMW",
                href: "/used-cars/bmw"
            },
            {
                type: "link",
                label: "Mercedes-Benz",
                href: "/used-cars/mercedes-benz"
            },
            {
                type: "link",
                label: "Volkswagen",
                href: "/used-cars/volkswagen"
            },
            {
                type: "link",
                label: "Audi",
                href: "/used-cars/audi"
            }, 
        ],
        sectionLink: "View all cars",
        href: "/used-cars"
    }, 
];
export var shopByBudget = [
    {
        heading: "Shop by budget",
        navItems: [
            {
                type: "link",
                label: "Cars under \xa37,500",
                href: "/used-cars?toPrice=7500"
            },
            {
                type: "link",
                label: "Cars under \xa310,000",
                href: "/used-cars?toPrice=10000"
            },
            {
                type: "link",
                label: "Cars under \xa315,000",
                href: "/used-cars?toPrice=15000"
            },
            {
                type: "link",
                label: "Cars under \xa320,000",
                href: "/used-cars?toPrice=20000"
            },
            {
                type: "link",
                label: "Cars over \xa320,000",
                href: "/used-cars?fromPrice=20000"
            }, 
        ]
    }, 
];
export var resourcesAndGuides = [
    {
        heading: "Resources and guides",
        navItems: [
            {
                type: "link",
                label: "Car reviews",
                href: "/reviews"
            },
            {
                type: "link",
                label: "Guides",
                href: "/guides"
            },
            {
                type: "link",
                label: "Electric cars",
                href: "/electric-cars"
            },
            {
                type: "link",
                label: "News",
                href: "/news"
            }, 
        ]
    }, 
];
export var shopByType = [
    {
        heading: "Shop by type",
        cards: [
            {
                type: "link",
                label: "SUV",
                href: "/used-cars/suv-body-type",
                image: "https://a.storyblok.com/f/132543/552x256/912e1f312e/suv.webp",
                alt: "SUV car"
            },
            {
                type: "link",
                label: "Hatchback",
                href: "/used-cars/hatchback-body-type",
                image: "https://a.storyblok.com/f/132543/552x256/3be1cb3f1e/hatchback.webp",
                alt: "Hatchback car"
            },
            {
                type: "link",
                label: "Estate",
                href: "/used-cars/estate-body-type",
                image: "https://a.storyblok.com/f/132543/552x256/561aaacd12/estate.webp",
                alt: "Estate car"
            },
            {
                type: "link",
                label: "Electric",
                href: "/used-cars/electric-fuel-type",
                image: "https://a.storyblok.com/f/132543/552x256/c4d58ac89f/electric.webp",
                alt: "Electric car"
            },
            {
                type: "link",
                label: "Saloon",
                href: "/used-cars/saloon-body-type",
                image: "https://a.storyblok.com/f/132543/552x256/3a5b8d2854/saloon.webp",
                alt: "Saloon car"
            },
            {
                type: "link",
                label: "Coupe",
                href: "/used-cars/coupe-body-type",
                image: "https://a.storyblok.com/f/132543/552x256/82135a63e5/coupe.webp",
                alt: "Coupe car"
            }, 
        ]
    }, 
];
export var navCard = {
    heading: "Help me choose",
    body: "Use our nifty tool and go from 1000s of cars to tailored top picks",
    image: "https://a.storyblok.com/f/132543/1280x740/99803728e7/help-me-choose-cars.webp",
    alt: "Help me choose cars",
    isImageFullWidth: true,
    sectionLink: "Help me choose",
    href: "/help-me-choose"
};
export var mobileNavCard = {
    heading: "Not sure where to start?",
    image: "https://a.storyblok.com/f/132543/1280x782/5fbb92e715/help-me-choose-mobile.webp",
    alt: "Help me choose cars",
    link: "Help me choose",
    href: "/help-me-choose"
};
