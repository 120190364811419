export var setCookie = function(param) {
    var name = param.name, value = param.value, expiryInDays = param.expiryInDays;
    var expiryDate = new Date();
    expiryDate.setTime(expiryDate.getTime() + expiryInDays * 24 * 60 * 60 * 1000);
    var expires = "expires=".concat(expiryDate.toUTCString());
    var isLocal = process.env["STAGE_NAME"] === "local";
    document.cookie = isLocal ? "".concat(name, "=").concat(value, ";").concat(expires, ";path=/") : "".concat(name, "=").concat(value, ";").concat(expires, ";path=/;domain=cinch.co.uk");
};
var ref;
export var getCookie = function(param) {
    var name = param.name;
    var ref1;
    return (ref = (ref1 = document.cookie.match("(^|;)\\s*".concat(name, "\\s*=\\s*([^;]+)"))) === null || ref1 === void 0 ? void 0 : ref1.pop()) !== null && ref !== void 0 ? ref : undefined;
};
