import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat.js";
import customParseFormat from "dayjs/plugin/customParseFormat.js";
import timezone from "dayjs/plugin/timezone.js";
import utc from "dayjs/plugin/utc.js";
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
var DAY_DATE_MONTH = "dddd Do MMMM";
var DAY_DATE_MONTH_YEAR = "dddd Do MMMM YYYY";
var DAY_MONTH_YEAR = "D MMMM YYYY";
var EUROPE_LONDON_TIMEZONE = "Europe/London";
var HOUR_AM_PM = "ha";
export var getDayDateMonthFormat = function(timestamp) {
    if (!timestamp) return "";
    var date = dayjs(timestamp);
    return date.format(DAY_DATE_MONTH);
};
export var getDayDateMonthYearFormat = function(timestamp) {
    if (!timestamp) return "";
    var date = dayjs(timestamp);
    return date.format(DAY_DATE_MONTH_YEAR);
};
export var getUTCFromDateAndTime = function(dateString, hours) {
    var dateAndTime = "".concat(dateString, " ").concat(hours, ":00");
    var date = dayjs.tz(dateAndTime, EUROPE_LONDON_TIMEZONE);
    return date.utc().format();
};
export var getUTCWithOffSet = function(dateString, hours) {
    var dateAndTime = "".concat(dateString, "T").concat(hours, ":00:00");
    var date = dayjs(dateAndTime).tz(EUROPE_LONDON_TIMEZONE);
    return date.format();
};
export var getDayMonthYearFormat = function(timestamp) {
    if (!timestamp) return "";
    var date = dayjs(timestamp);
    return date.format(DAY_MONTH_YEAR);
};
export var getDeliveryTimeInAmPmFormat = function(timestamp) {
    if (!timestamp) return "";
    var date = dayjs(timestamp);
    return date.format(HOUR_AM_PM);
};
