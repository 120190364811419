var ref;
var getCookie = function(param) {
    var name = param.name;
    var ref1;
    return (ref = (ref1 = document.cookie.match("(^|;)\\s*".concat(name, "\\s*=\\s*([^;]+)"))) === null || ref1 === void 0 ? void 0 : ref1.pop()) !== null && ref !== void 0 ? ref : undefined;
};
var cookiePreferencesCookieParser = function(cookieContents) {
    return cookieContents ? JSON.parse(cookieContents) : undefined;
};
var cookiePreferences = function() {
    return cookiePreferencesCookieParser(getCookie({
        name: "cp"
    }));
};
var ref1;
export var hasConsentedToCookies = function(type) {
    var ref;
    return (ref1 = (ref = cookiePreferences()) === null || ref === void 0 ? void 0 : ref[type]) !== null && ref1 !== void 0 ? ref1 : false;
};
