var ADMIN_FEE = 99;
export var sentenceCase = function(input) {
    return input.charAt(0).toUpperCase() + input.slice(1).toLowerCase();
};
export var formatEngineSizeInLitres = function(engineCapacityInCc) {
    return engineCapacityInCc ? "".concat((engineCapacityInCc / 1000).toFixed(1), "L") : "";
};
export var formatFuelType = function(input) {
    if (!input) {
        return "-";
    }
    return input.toUpperCase() === "LPG" ? input.toUpperCase() : sentenceCase(input);
};
export var formatMileage = function(mileage) {
    return Intl.NumberFormat().format(mileage);
};
export var calculateVehiclePriceWithAdminFee = function(price) {
    return price + ADMIN_FEE;
};
