import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import { Env, readFromEnv } from "@cinch-nx/environments";
import { create } from "zustand";
import axios from "axios";
// types
export * from "./lib/types";
// hooks
export * from "./lib/hooks";
// endpoints
var USER_PROFILE_URL = readFromEnv(Env.ProfileServiceUrl);
export var FAVOURITES_URL = "".concat(USER_PROFILE_URL, "/favourites");
export var useFavouritesStore = create(function(set) {
    return {
        addFavourite: function() {
            var _ref = _async_to_generator(function(vehicleId) {
                var addFavouritesReq;
                return _ts_generator(this, function(_state) {
                    switch(_state.label){
                        case 0:
                            return [
                                4,
                                axios.post(FAVOURITES_URL, {
                                    vehicleId: vehicleId
                                })
                            ];
                        case 1:
                            addFavouritesReq = _state.sent();
                            set(function() {
                                return {
                                    favourites: addFavouritesReq.data.favouriteVehicles
                                };
                            });
                            return [
                                2
                            ];
                    }
                });
            });
            return function(vehicleId) {
                return _ref.apply(this, arguments);
            };
        }(),
        removeFavourite: function() {
            var _ref = _async_to_generator(function(vehicleId) {
                var removeFavouritesReq;
                return _ts_generator(this, function(_state) {
                    switch(_state.label){
                        case 0:
                            return [
                                4,
                                axios.delete(FAVOURITES_URL, {
                                    data: {
                                        vehicleId: vehicleId
                                    }
                                })
                            ];
                        case 1:
                            removeFavouritesReq = _state.sent();
                            set(function() {
                                return {
                                    favourites: removeFavouritesReq.data.favouriteVehicles
                                };
                            });
                            return [
                                2
                            ];
                    }
                });
            });
            return function(vehicleId) {
                return _ref.apply(this, arguments);
            };
        }(),
        fetchFavourites: function() {
            var _ref = _async_to_generator(function(withVehicleData) {
                var favouritesReq, error, errorMessage;
                return _ts_generator(this, function(_state) {
                    switch(_state.label){
                        case 0:
                            _state.trys.push([
                                0,
                                2,
                                ,
                                3
                            ]);
                            return [
                                4,
                                axios.get("".concat(FAVOURITES_URL, "?withVehicleData=").concat(withVehicleData ? "true" : "false"))
                            ];
                        case 1:
                            favouritesReq = _state.sent();
                            set(function() {
                                return {
                                    favourites: favouritesReq.data.favouriteVehicles,
                                    favouritesError: undefined,
                                    fetchFavouritesStatus: "valid"
                                };
                            });
                            return [
                                3,
                                3
                            ];
                        case 2:
                            error = _state.sent();
                            errorMessage = "Unable to fetch favourites";
                            if (axios.isAxiosError(error)) {
                                errorMessage = "Favourites error: ".concat(error.status, ", ").concat(error.message);
                            }
                            set(function() {
                                return {
                                    favouritesError: errorMessage,
                                    fetchFavouritesStatus: "invalid"
                                };
                            });
                            return [
                                3,
                                3
                            ];
                        case 3:
                            return [
                                2
                            ];
                    }
                });
            });
            return function(withVehicleData) {
                return _ref.apply(this, arguments);
            };
        }(),
        favourites: [],
        favouritesError: undefined,
        fetchFavouritesStatus: "pending"
    };
});
