import _sliced_to_array from "@swc/helpers/src/_sliced_to_array.mjs";
export var toUrlSearchParams = function(params) {
    var urlParams = new URLSearchParams();
    Object.entries(params !== null && params !== void 0 ? params : {}).forEach(function(param) {
        var _param = _sliced_to_array(param, 2), key = _param[0], value = _param[1];
        if (typeof value === "string") {
            urlParams.append(key, value);
            return;
        }
        if (Array.isArray(value)) {
            value.forEach(function(nestedValue) {
                if (typeof nestedValue === "string") {
                    urlParams.append(key, nestedValue);
                } else {
                    urlParams.append(key, JSON.stringify(nestedValue));
                }
            });
            return;
        }
        urlParams.append(key, JSON.stringify(value));
    });
    return urlParams;
};
