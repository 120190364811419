import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
import { identify, page, track } from "@cinch-labs/customer-data-segment";
import { isBrowser } from "@cinch-labs/shared-util";
import { create } from "zustand";
import { sendDigitalDataEvent, sendHotjarEvent, sendHotjarTag, sendHotjarTrigger } from "../data-layer/datalayer";
import { TrackingEventTypes } from "./types";
export var getCookie = function(param) {
    var name = param.name;
    var ref;
    if (!isBrowser) {
        return undefined;
    }
    var ref1;
    return (ref1 = (ref = document.cookie.match("(^|;)\\s*".concat(name, "\\s*=\\s*([^;]+)"))) === null || ref === void 0 ? void 0 : ref.pop()) !== null && ref1 !== void 0 ? ref1 : undefined;
};
export var getCookieConsentPreferences = function(name) {
    return cookiePreferencesCookieParser(getCookie({
        name: name
    }));
};
var cookiePreferencesCookieParser = function(cookieContents) {
    return cookieContents ? JSON.parse(cookieContents) : undefined;
};
export var useAnalytics = create(function(set, get) {
    return {
        eventsQueue: [],
        next: function() {
            return get().eventsQueue.shift();
        },
        hasAcceptedCookies: false,
        setHasAcceptedCookies: function(accepted) {
            return set({
                hasAcceptedCookies: accepted
            });
        },
        hasLoadedScripts: false,
        setHasLoadedScripts: function(loaded) {
            return set({
                hasLoadedScripts: loaded
            });
        },
        trackEvent: function(event) {
            return set(function(state) {
                return {
                    eventsQueue: _to_consumable_array(state.eventsQueue).concat([
                        event
                    ])
                };
            });
        },
        processEventsQueue: function() {
            var cookiePreferences = getCookieConsentPreferences("cp");
            if (!(cookiePreferences === null || cookiePreferences === void 0 ? void 0 : cookiePreferences.analytics)) {
                return;
            }
            while(get().eventsQueue.length > 0){
                var event = get().next();
                if (!event) {
                    return;
                }
                switch(event.type){
                    case TrackingEventTypes.IDENTIFY:
                        if (event.userId) {
                            identify({
                                userId: event.userId,
                                data: event.data || {}
                            });
                        } else {
                            identify({
                                data: event.data || {}
                            });
                        }
                        break;
                    case TrackingEventTypes.TRACK:
                        track(event.eventName, event.data);
                        break;
                    case TrackingEventTypes.ADOBE:
                        sendDigitalDataEvent(event.data);
                        break;
                    case TrackingEventTypes.PAGE:
                        page(event.eventName, event.data);
                        sendDigitalDataEvent(event.data);
                        break;
                    case TrackingEventTypes.HOTJAR_TAG:
                        sendHotjarTag(event.eventName);
                        break;
                    case TrackingEventTypes.HOTJAR_TRIGGER:
                        sendHotjarTrigger(event.eventName);
                        break;
                    case TrackingEventTypes.HOTJAR_EVENT:
                        sendHotjarEvent(event.eventName);
                        break;
                    case TrackingEventTypes.PAGE_NO_ADOBE:
                        page(event.eventName, event.data);
                        break;
                }
            }
        }
    };
});
