import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import { waitFor, screen } from "@testing-library/react";
export var waitForIconsToLoad = function() {
    var _ref = _async_to_generator(function() {
        var e;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    _state.trys.push([
                        0,
                        2,
                        ,
                        3
                    ]);
                    return [
                        4,
                        waitFor(function() {
                            return screen.getAllByTestId(function(id) {
                                return id.startsWith("SvgIcon");
                            });
                        })
                    ];
                case 1:
                    _state.sent();
                    return [
                        3,
                        3
                    ];
                case 2:
                    e = _state.sent();
                    console.warn("Icons not loaded... continuing");
                    return [
                        3,
                        3
                    ];
                case 3:
                    return [
                        2
                    ];
            }
        });
    });
    return function waitForIconsToLoad() {
        return _ref.apply(this, arguments);
    };
}();
