var isProd = process.env["STAGE_NAME"] === "production";
var trackingServerDomain = isProd ? "bcacinchprod.112.2o7.net" : "bcaoxforddev.112.2o7.net";
export var CLIENT_TIMEOUT = 3000;
export var targetClientOptions = {
    client: "bca",
    organizationId: "E5F2C8A15481C0E20A4C98BC@AdobeOrg",
    timeout: CLIENT_TIMEOUT,
    serverDomain: trackingServerDomain,
    decisioningMethod: "on-device",
    pollingInterval: 60000
};
