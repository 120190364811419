import _class_call_check from "@swc/helpers/src/_class_call_check.mjs";
import "intersection-observer";
var Intersection = function Intersection() {
    "use strict";
    _class_call_check(this, Intersection);
};
Intersection.listeners = new Map();
var hasIntersectionObserver = function(element) {
    return Intersection.listeners.has(element);
};
export var removeIntersectionObserver = function(element) {
    Intersection.observer.unobserve(element);
    Intersection.listeners.delete(element);
};
export var addIntersectionObserver = function(// eslint-disable-next-line @typescript-eslint/no-explicit-any
callback, element) {
    if (Intersection.listeners.size === 0) {
        Intersection.observer = new IntersectionObserver(function(entries) {
            entries.forEach(function(entry) {
                var target = entry.target;
                var observer = Intersection.listeners.get(target);
                if (observer) {
                    observer.callback(entry.isIntersecting || !!entry.intersectionRatio, observer.isInitialLoad);
                    observer.isInitialLoad = false;
                }
            });
        });
    }
    if (!hasIntersectionObserver(element)) {
        Intersection.listeners.set(element, {
            callback: callback,
            isInitialLoad: true
        });
        Intersection.observer.observe(element);
    }
};
