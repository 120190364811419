var tokenIsInDate = function(token) {
    return token.expires_at > Date.now() / 1000;
};
var isToken = function(maybeToken) {
    return Object.prototype.hasOwnProperty.call(maybeToken, "expires_at");
};
export var getToken = function(param) {
    var authKey = param.authKey, legacyAuthKey = param.legacyAuthKey;
    var ref;
    var maybeLegacyAuthToken = JSON.parse((ref = localStorage.getItem(authKey)) !== null && ref !== void 0 ? ref : "{}");
    var ref1;
    var maybeAuthToken = JSON.parse((ref1 = localStorage.getItem(legacyAuthKey)) !== null && ref1 !== void 0 ? ref1 : "{}");
    if (isToken(maybeAuthToken) && tokenIsInDate(maybeAuthToken)) {
        return maybeAuthToken;
    }
    if (isToken(maybeLegacyAuthToken) && tokenIsInDate(maybeLegacyAuthToken)) {
        return maybeLegacyAuthToken;
    }
    return null;
};
