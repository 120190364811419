import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { getEMaCServiceWarrantyEligibilityPeriod } from "@cinch-labs/shared-util";
dayjs.extend(utc);
export var checkEMaCServiceWarrantyEligibility = function(vehicleOrder, allCinchCareOrders) {
    var ref;
    var deliverySlotDate = (ref = vehicleOrder.deliverySlot) === null || ref === void 0 ? void 0 : ref.date;
    var deliveryDate = deliverySlotDate ? dayjs.utc(deliverySlotDate) : null;
    if (!orderIsCompleted(vehicleOrder.orderStatus) || !vehicleOrder.completedAt) {
        return false;
    }
    var postDeliveryEligibleWarrantyPeriodDays = getEMaCServiceWarrantyEligibilityPeriod();
    var isWithinEligiblePostDeliveryPeriod = dayjs.utc().subtract(postDeliveryEligibleWarrantyPeriodDays, "days").subtract(1, "days");
    var alreadyHasCinchCare = allCinchCareOrders === null || allCinchCareOrders === void 0 ? void 0 : allCinchCareOrders.map(function(plan) {
        return plan.vehicleVrm;
    }).includes(vehicleOrder.vehicle.vrm);
    if (!deliveryDate || alreadyHasCinchCare) {
        return false;
    }
    return deliveryDate.isAfter(isWithinEligiblePostDeliveryPeriod, "seconds");
};
var orderIsCompleted = function(orderStatus) {
    return orderStatus === "OrderCompleted" || orderStatus === "Fulfilled";
};
