import { useEffect, useState } from "react";
import { getCookieConsentPreferences, hasCookieConsentBeenSet, setCookieConsentPreferences } from "../utils/cookies";
export var defaultCookiePreferences = {
    advertising: false,
    analytics: false,
    functional: false
};
export function useCookiePreferences(setLoaded) {
    var ref = useState(), cookiePreferences = ref[0], setCookiePreferencesState = ref[1];
    useEffect(function() {
        var cookie = getCookieConsentPreferences();
        if (cookie) {
            setCookiePreferencesState(cookie);
        } else {
            setCookiePreferencesState(defaultCookiePreferences);
        }
        setLoaded === null || setLoaded === void 0 ? void 0 : setLoaded(true);
    }, []);
    var setCookiePreferences = function(state) {
        setCookiePreferencesState(state);
        setCookieConsentPreferences(state);
    };
    return {
        cookiePreferences: cookiePreferences,
        setCookiePreferences: setCookiePreferences,
        isCookieConsentSet: hasCookieConsentBeenSet()
    };
}
