import { constants } from "@cinch-nx/data-search";
import { fetcher, hasTokenExpired, useUserStore } from "@cinch-nx/data-user";
import useSWR from "swr";
import { FAVOURITES_URL } from "../../index";
export var useFavouriteVehicles = function() {
    var withVehicleData = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : false;
    var ref;
    var ref1 = useUserStore(), setStatus = ref1.setStatus, status = ref1.status;
    var ref2 = useSWR(status === "valid" && FAVOURITES_URL, fetcher), favouritesData = ref2.data, favouritesError = ref2.error, mutateFavourites = ref2.mutate;
    var directFavouritesTokenExpired = hasTokenExpired(favouritesError);
    if (directFavouritesTokenExpired) {
        setStatus("expired");
    }
    var ref3;
    var favouritesAmount = (ref3 = favouritesData === null || favouritesData === void 0 ? void 0 : (ref = favouritesData.favouriteVehicles) === null || ref === void 0 ? void 0 : ref.length) !== null && ref3 !== void 0 ? ref3 : 0;
    var ref4 = useSWR(function() {
        return withVehicleData && !!favouritesAmount ? "".concat(constants.VEHICLE_SEARCH_URL, "?vehicleIds=").concat(favouritesData === null || favouritesData === void 0 ? void 0 : favouritesData.favouriteVehicles.map(function(param) {
            var vehicleId = param.vehicleId;
            return vehicleId;
        }).join(","), "&excludeUnavailable=false&queryOnly=true") : null;
    }, fetcher), vehicleData = ref4.data, vehicleDetailsError = ref4.error;
    var favouriteVehiclesTokenExpired = hasTokenExpired(favouritesError);
    if (favouriteVehiclesTokenExpired) {
        setStatus("expired");
    }
    return {
        favouritesData: favouritesData,
        favouritesAmount: favouritesAmount,
        favouritesError: favouritesError,
        vehicleData: vehicleData,
        vehicleDetailsError: vehicleDetailsError,
        mutateFavourites: mutateFavourites
    };
};
