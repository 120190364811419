var getEnvironmentFromURL = function(hostname) {
    switch(hostname){
        case "www.cinch.co.uk":
        case "landing.api.cinch.co.uk":
            return "production";
        case "cambridge.preview.aws.cinch.co.uk":
            return "cambridge";
        case "landing.preview.aws.cinch.co.uk":
            return "development";
        default:
            if (hostname.includes(".feature.")) {
                return hostname.split(".")[0];
            }
            return hostname;
    }
};
var hasWindow = "object" !== "undefined";
export { hasWindow, getEnvironmentFromURL };
