export var Context;
(function(Context) {
    Context["SEARCH_ALERTS_SERVICE"] = "search-alerts";
    Context["SEARCH_SUGGEST_SERVICE"] = "search-suggest";
    Context["SEARCH_SIMILAR_SERVICE"] = "search-similar";
    Context["SQUAD"] = "search";
    Context["COMPARE_SERVICE"] = "compare";
    Context["SEARCH_PART_EX_SERVICE"] = "search-part-exchange";
    Context["SEARCH_SEO_POPULAR_LINKS"] = "search-seo-popular-links";
    Context["SEARCH_FAQ_STORYBLOK_CONTENT"] = "search-storyblok-content-faq";
    Context["SEARCH_STORYBLOK_INTRO_CONTENT"] = "search-storyblok-content-intro";
    Context["SEARCH_OUTRO_CONTENT"] = "search-storyblok-content-outro";
    Context["SEARCH_FILTER_SELECTED"] = "search-filter-selected";
    Context["SEARCH_VALUE_PROPOSITIONS"] = "value-proposition-selected";
    Context["SEARCH_FREE_TEXT_SEARCH"] = "free-text-search";
    Context["SEARCH_RECENTLY_VIEWED_VEHICLES"] = "recently-viewed-vehicles";
    Context["SEARCH_ALTERNATIVE_VEHICLES"] = "alternative-vehicles";
    Context["CINCHAPP"] = "cinchapp";
    Context["SEARCH_MULTIPLE_IMAGES"] = "search-multiple-images";
    Context["SEARCH_AVAILABLE_TO_VIEW"] = "available-to-view";
})(Context || (Context = {}));
