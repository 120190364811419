export var carFinance = {
    sectionTitle: "Car finance",
    navItems: [
        {
            type: "link",
            label: "How it works",
            href: "/car-finance"
        },
        {
            type: "link",
            label: "Hire purchase (HP)",
            href: "/car-finance/hire-purchase"
        },
        {
            type: "link",
            label: "Personal contract purchase (PCP)",
            href: "/car-finance/personal-contract-purchase"
        }, 
    ]
};
export var articles = {
    sectionTitle: "Helpful guides",
    navArticles: [
        {
            title: "Can you get car finance with bad credit?",
            image: "https://a.storyblok.com/f/132543/1280x731/dbd22c266f/bad-credit-car-finance.webp",
            href: "/guides/car-finance/bad-credit-car-finance",
            alt: "car finance"
        },
        {
            title: "How to part exchange a car with finance – PCP and HP finance",
            image: "https://a.storyblok.com/f/132543/1280x731/eea6792d02/part-exchange-financed-car.webp",
            href: "/guides/car-finance/part-exchange-financed-car",
            alt: "part exchange financed car"
        },
        {
            title: "How to find out how much finance is left on my car",
            image: "https://a.storyblok.com/f/132543/1280x731/5b4c8f1fd2/how-to-find-out-how-much-finance-is-left-on-a-car.webp",
            href: "/guides/car-finance/how-to-find-out-how-much-finance-is-left-on-a-car",
            alt: "finance is left on a car"
        }, 
    ],
    sectionLink: "All finance guides",
    href: "/guides/car-finance"
};
