import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _instanceof from "@swc/helpers/src/_instanceof.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import { getStories } from "../get-stories/get-stories";
import { traceFunction, setTag, tagError } from "@core/datadog-server";
var MAX_PER_PAGE = 100;
function getAllStoriesFn(params) {
    return _getAllStoriesFn.apply(this, arguments);
}
function _getAllStoriesFn() {
    _getAllStoriesFn = /**
 * Gets all of the stories based on the `starts_with` slug.
 * @param params
 * @returns {Promise<GetStoriesResponse<Content>[]>}
 */ _async_to_generator(function(params) {
        var requestParams, initialRequest, ref, total, _stories, stories, numberOfExtraRequests, otherStories;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    requestParams = _object_spread_props(_object_spread({}, params), {
                        page: 1,
                        per_page: MAX_PER_PAGE
                    });
                    setTag("getAllStories.request[0].params", requestParams);
                    return [
                        4,
                        getStories(requestParams)
                    ];
                case 1:
                    initialRequest = _state.sent();
                    total = (ref = initialRequest === null || initialRequest === void 0 ? void 0 : initialRequest.total) !== null && ref !== void 0 ? ref : 0;
                    stories = (_stories = initialRequest.stories) !== null && _stories !== void 0 ? _stories : [];
                    numberOfExtraRequests = Math.ceil(total / MAX_PER_PAGE) - 1;
                    setTag("getAllStories.requests.count", numberOfExtraRequests.toString());
                    if (numberOfExtraRequests === 0) {
                        return [
                            2,
                            {
                                total: total,
                                stories: stories
                            }
                        ];
                    }
                    return [
                        4,
                        Promise.all(Array.from({
                            length: numberOfExtraRequests
                        }, function(_val, idx) {
                            var requestParams = _object_spread_props(_object_spread({}, params), {
                                page: idx + 2,
                                per_page: MAX_PER_PAGE
                            });
                            setTag("getAllStories.request[".concat(idx + 1, "].params"), requestParams);
                            return getStories(requestParams);
                        })).then(function(responses) {
                            return responses.filter(Boolean).flatMap(function(res) {
                                return res === null || res === void 0 ? void 0 : res.stories;
                            });
                        }).catch(function(error) {
                            if (typeof error === "string" || _instanceof(error, Error)) {
                                tagError(error);
                            }
                            // rethrow the error so that the caller can handle it
                            throw error;
                        })
                    ];
                case 2:
                    otherStories = _state.sent();
                    stories = _to_consumable_array(new Set(_to_consumable_array(stories).concat(_to_consumable_array(otherStories))));
                    return [
                        2,
                        {
                            total: total,
                            stories: stories
                        }
                    ];
            }
        });
    });
    return _getAllStoriesFn.apply(this, arguments);
}
export var getAllStories = traceFunction("storyblok.getAllStories", getAllStoriesFn);
