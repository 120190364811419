import { useEffect, useState } from "react";
export var useCookieEditorTime = function(isModalOpen) {
    var ref = useState(0), cookieEditorTime = ref[0], setCookieEditorTime = ref[1];
    var ref1 = useState(Date.now()), startTime = ref1[0];
    useEffect(function() {
        if (!cookieEditorTime && isModalOpen) {
            setCookieEditorTime(Math.round(Date.now() / 1000 - startTime / 1000));
        }
    }, [
        cookieEditorTime,
        isModalOpen,
        startTime
    ]);
    return {
        cookieEditorTime: cookieEditorTime,
        setCookieEditorTime: setCookieEditorTime,
        startTime: startTime
    };
};
