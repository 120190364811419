import { EventActions, EventCategories, EventNames } from "../types";
export var basketBannerReturnToCheckoutEvent = function() {
    return {
        event: {
            name: EventNames.click,
            category: EventCategories.button,
            label: "checkout-resume-banner",
            action: EventActions.returnToCheckout
        }
    };
};
export var basketBannerCloseBannerEvent = function() {
    return {
        event: {
            name: EventNames.click,
            category: EventCategories.button,
            label: "checkout-resume-banner",
            action: EventActions.modalClosed
        }
    };
};
export var basketBannerVehicleImageClickEvent = function() {
    return {
        event: {
            name: EventNames.click,
            category: EventCategories.image,
            action: EventActions.deadClick,
            label: "checkout-return-car-image"
        }
    };
};
export var basketBannerNoLongerWantVehicleClickEvent = function() {
    return {
        event: {
            name: EventNames.click,
            category: EventCategories.button,
            action: EventActions.dontWantCare,
            label: "checkout-resume-banner"
        }
    };
};
