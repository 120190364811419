import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _instanceof from "@swc/helpers/src/_instanceof.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import { STORYBLOK_BFF_URL } from "../../util/api";
import { acceptedLinkStarts } from "./schema";
import { traceFunction, tagError, setTag } from "@core/datadog-server";
function getLinksFn(startsWith) {
    return _getLinksFn.apply(this, arguments);
}
function _getLinksFn() {
    _getLinksFn = _async_to_generator(function(startsWith) {
        var linksToPull, rawParams, urlParams, url, request, ref, data, ref1, error;
        var _arguments = arguments;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    linksToPull = _arguments.length > 1 && _arguments[1] !== void 0 ? _arguments[1] : 10;
                    startsWith = acceptedLinkStarts.parse(startsWith);
                    rawParams = {
                        cv: Date.now().toString(),
                        version: "published"
                    };
                    if (startsWith) {
                        rawParams["starts_with"] = startsWith;
                    }
                    if (linksToPull) {
                        rawParams["per_page"] = linksToPull.toString();
                    }
                    urlParams = new URLSearchParams(rawParams);
                    url = "".concat(STORYBLOK_BFF_URL, "/get-links?").concat(urlParams.toString());
                    _state.label = 1;
                case 1:
                    _state.trys.push([
                        1,
                        4,
                        ,
                        5
                    ]);
                    return [
                        4,
                        fetch(url)
                    ];
                case 2:
                    request = _state.sent();
                    setTag("request.url", url);
                    setTag("request.params", JSON.stringify(Object.fromEntries(urlParams.entries()), null, 2));
                    setTag("request.method", "GET");
                    setTag("request.status", request.status);
                    setTag("request.content-length", (ref = request.headers.get("content-length")) !== null && ref !== void 0 ? ref : "0");
                    return [
                        4,
                        request.json()
                    ];
                case 3:
                    data = _state.sent();
                    if (Object.keys((ref1 = data === null || data === void 0 ? void 0 : data.links) !== null && ref1 !== void 0 ? ref1 : {}).length === 0) return [
                        2,
                        {}
                    ];
                    return [
                        2,
                        data.links
                    ];
                case 4:
                    error = _state.sent();
                    if (typeof error === "string" || _instanceof(error, Error)) {
                        tagError(error);
                    }
                    throw new Error("links endpoint not working");
                case 5:
                    return [
                        2
                    ];
            }
        });
    });
    return _getLinksFn.apply(this, arguments);
}
export var getLinks = traceFunction("storyblok.getLinks", getLinksFn);
