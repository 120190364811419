import { Env } from "./environment/environment.types";
import { readFromEnv } from "./environment/read-from-env";
export var targetActivities = {
    FRED: readFromEnv(Env.Fred),
    I_DONT_WANT_THIS_CAR: readFromEnv(Env.IDontWantThisCar),
    FOMO_EXPERIMENT_ID: readFromEnv(Env.FomoExperimentId),
    REQUEST_CALLBACK_EXPERIMENT_ID: readFromEnv(Env.RequestCallbackExperimentId),
    SEARCH_MULTIPLE_IMAGES_EXPERIMENT_ID: readFromEnv(Env.SearchMultipleImagesExperimentId),
    SSR_PROD_TEST: readFromEnv(Env.SSRProdTest),
    CLIENT_PROD_TEST: readFromEnv(Env.ClientProdTest),
    NEXT_BEST_ACTION_EXPERIMENT_ID: readFromEnv(Env.NextBestActionExperimentId),
    SEARCH_VEHICLE_OPEN_NEW_TAB_ID: readFromEnv(Env.SearchVehicleOpenNewTabId),
    BUY_NOW_CTA_MESSAGING_EXPERIMENT_ID: readFromEnv(Env.BuyNowCTAMessaging)
};
export var targetActivityLocations = {
    HOMEPAGE_SSR_TEST: readFromEnv(Env.HomepageSSRTest)
};
