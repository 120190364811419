import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
export var SHARED_UI_HEADER_BFF = "https://bsyi8f632i.execute-api.eu-west-1.amazonaws.com/prod/search-data";
export var getSharedUiHeaderBffData = function() {
    var _ref = _async_to_generator(function() {
        var response, searchDataResponse, error;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    _state.trys.push([
                        0,
                        3,
                        ,
                        4
                    ]);
                    return [
                        4,
                        fetch(SHARED_UI_HEADER_BFF)
                    ];
                case 1:
                    response = _state.sent();
                    return [
                        4,
                        response.json()
                    ];
                case 2:
                    searchDataResponse = _state.sent();
                    return [
                        2,
                        searchDataResponse
                    ];
                case 3:
                    error = _state.sent();
                    console.log("Error with fetching searchData", error);
                    return [
                        2,
                        null
                    ];
                case 4:
                    return [
                        2
                    ];
            }
        });
    });
    return function getSharedUiHeaderBffData() {
        return _ref.apply(this, arguments);
    };
}();
