import { datadogLogs } from "@datadog/browser-logs";
import { getEnvironmentFromURL, hasWindow } from "../utils";
export var initDataDogLogging = function(param) {
    var service = param.serviceName, clientToken = param.datadogRumClientToken;
    if (hasWindow) {
        var currentEnv = getEnvironmentFromURL(window.location.hostname);
        datadogLogs.init({
            clientToken: clientToken,
            site: "datadoghq.eu",
            forwardErrorsToLogs: true,
            service: service,
            sessionSampleRate: 100,
            silentMultipleInit: true
        });
        datadogLogs.setGlobalContextProperty("env", currentEnv);
    }
};
