export var howCinchWorks = {
    sectionTitle: "Popular makes",
    navItems: [
        {
            type: "link",
            label: "Buying a car online",
            href: "/our-commitment"
        },
        {
            type: "link",
            label: "90-day warranty",
            href: "/car-care/90-day-warranty"
        },
        {
            type: "link",
            label: "Quality standards",
            href: "/car-inspection"
        },
        {
            type: "link",
            label: "Money-back guarantee",
            href: "/returns"
        },
        {
            type: "link",
            label: "Home delivery",
            href: "/delivery"
        },
        {
            type: "link",
            label: "Collection",
            href: "/car-collections"
        },
        {
            type: "link",
            label: "Visit our stores",
            href: "/store"
        }, 
    ]
};
export var howCinchWorksFAQs = {
    sectionTitle: "FAQs",
    faqs: [
        {
            label: "Do cinch offer new cars?",
            href: "/support#cinch cars…what to expect-1",
            ariaLabel: "Do cinch offer new cars?"
        },
        {
            label: "Do your cars come with an MOT?",
            href: "/support#cinch cars…what to expect-8",
            ariaLabel: "Do your cars come with an MOT?"
        },
        {
            label: "How do I apply for finance?",
            href: "/support#Finance and payments-4",
            ariaLabel: "How do I apply for finance?"
        }, 
    ],
    sectionLink: "Go to help and support",
    href: "/support"
};
