import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _instanceof from "@swc/helpers/src/_instanceof.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import { traceFunction, tagError, setTag } from "@core/datadog-server";
import { STORYBLOK_BFF_URL } from "../../util/api";
import { toUrlSearchParams } from "../../util/to-url-search-params";
import { getDatasourceEntriesParams, getDatasourceEntriesResponse } from "./schema";
export function getDatasourceEntriesfn(params) {
    return _getDatasourceEntriesfn.apply(this, arguments);
}
function _getDatasourceEntriesfn() {
    _getDatasourceEntriesfn = _async_to_generator(function(params) {
        var ref, _storyblokUrl, storyblokUrl, urlParams, url, request, _, error;
        var _arguments = arguments;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    ref = _arguments.length > 1 && _arguments[1] !== void 0 ? _arguments[1] : {}, _storyblokUrl = ref.storyblokUrl, storyblokUrl = _storyblokUrl === void 0 ? STORYBLOK_BFF_URL : _storyblokUrl;
                    urlParams = getDatasourceEntriesParams.transform(toUrlSearchParams).parse(params);
                    url = "".concat(storyblokUrl, "/get-datasource-entries?").concat(urlParams.toString());
                    _state.label = 1;
                case 1:
                    _state.trys.push([
                        1,
                        4,
                        ,
                        5
                    ]);
                    return [
                        4,
                        fetch(url)
                    ];
                case 2:
                    request = _state.sent();
                    setTag("request.url", url);
                    setTag("request.params", JSON.stringify(Object.fromEntries(urlParams.entries()), null, 2));
                    setTag("request.method", "GET");
                    setTag("request.status", request.status);
                    if (request.status !== 200) {
                        throw new Error("'get-datasource-entries' responded with non-OK response status ".concat(request.status));
                    }
                    _ = getDatasourceEntriesResponse.parse;
                    return [
                        4,
                        request.json()
                    ];
                case 3:
                    return [
                        2,
                        _.apply(getDatasourceEntriesResponse, [
                            _state.sent()
                        ])
                    ];
                case 4:
                    error = _state.sent();
                    if (typeof error === "string" || _instanceof(error, Error)) {
                        tagError(error);
                    }
                    if (_instanceof(error, Error)) {
                        // eslint-disable-next-line unicorn/prefer-type-error
                        throw new Error("'get-datasource-entries' endpoint threw an error: ".concat(error.message));
                    }
                    throw new Error("'get-datasource-entries' endpoint threw an unknown error: ".concat(error));
                case 5:
                    return [
                        2
                    ];
            }
        });
    });
    return _getDatasourceEntriesfn.apply(this, arguments);
}
export var getDatasourceEntries = traceFunction("storyblok.getDatasourceEntries", getDatasourceEntriesfn);
