import axios from "axios";
import { datadogRum } from "@datadog/browser-rum";
export var sendCheckoutError = function(description, error, orderId) {
    var errorPayload = axios.isAxiosError(error) ? {
        message: error.message,
        response: error.response
    } : error;
    console.log({
        errorPayload: errorPayload
    });
    datadogRum.addError(description, {
        location: "checkout-service-nx",
        orderId: orderId,
        error: errorPayload
    });
};
