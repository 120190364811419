import _object_spread from "@swc/helpers/src/_object_spread.mjs";
export var EnvironmentFromFile = {
    Fred: "FRED",
    IDontWantThisCar: "I_DONT_WANT_THIS_CAR",
    FomoExperimentId: "FOMO_EXPERIMENT_ID",
    RequestCallbackExperimentId: "REQUEST_CALLBACK_EXPERIMENT_ID",
    SearchMultipleImagesExperimentId: "SEARCH_MULTIPLE_IMAGES_EXPERIMENT_ID",
    SSRProdTest: "SSR_PROD_TEST",
    ClientProdTest: "CLIENT_PROD_TEST",
    HomepageSSRTest: "HOMEPAGE_SSR_TEST",
    NextBestActionExperimentId: "NEXT_BEST_ACTION_EXPERIMENT_ID",
    SearchVehicleOpenNewTabId: "SEARCH_VEHICLE_OPEN_NEW_TAB_ID",
    BuyNowCTAMessaging: "BUY_NOW_CTA_MESSAGING_EXPERIMENT_ID"
};
export var Env = _object_spread({}, EnvironmentFromFile);
