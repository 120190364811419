export var driveTypeValueMappings = new Map([
    [
        "four-wheel-drive",
        "four-wheel drive"
    ],
    [
        "rwd",
        "rear-wheel drive"
    ],
    [
        "fwd",
        "front-wheel drive"
    ], 
]);
