import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { getToken } from "@cinch-labs/shared-util";
import { constants } from "@cinch-nx/data-search";
import { Env, readFromEnv } from "@cinch-nx/environments";
import axios from "axios";
export var UNAUTHORISED_ENDPOINTS = [
    constants.VEHICLE_SEARCH_URL
];
axios.interceptors.request.use(function(config) {
    var token = getToken({
        authKey: readFromEnv(Env.AuthKey),
        legacyAuthKey: readFromEnv(Env.LegacyAuthKey)
    });
    if (!UNAUTHORISED_ENDPOINTS.some(function(endpoint) {
        var ref;
        return (ref = config.url) === null || ref === void 0 ? void 0 : ref.includes(endpoint);
    })) {
        config.headers = _object_spread_props(_object_spread({}, config.headers), {
            Authorization: "Bearer ".concat(token === null || token === void 0 ? void 0 : token.access_token)
        });
    }
    return config;
});
axios.interceptors.response.use(undefined, function(error) {
    return Promise.reject(error);
});
export var fetcher = function(url) {
    return axios.get(url).then(function(res) {
        return res.data;
    });
};
