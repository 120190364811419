import _sliced_to_array from "@swc/helpers/src/_sliced_to_array.mjs";
import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
var parseBuildId = function(buildId) {
    var fallback = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
    if (buildId != "development") {
        var ref = _sliced_to_array(buildId.split("::"), 4), appName = ref[0], commitSha = ref[1], githubRunId = ref[2], stageName = ref[3];
        return {
            appName: appName,
            commitSha: commitSha,
            githubRunId: githubRunId,
            stageName: stageName
        };
    }
    var _appName, _commitSha, _githubRunId, _stageName;
    return {
        appName: (_appName = fallback.appName) !== null && _appName !== void 0 ? _appName : "appName",
        commitSha: (_commitSha = fallback.commitSha) !== null && _commitSha !== void 0 ? _commitSha : "commitSha",
        githubRunId: (_githubRunId = fallback.githubRunId) !== null && _githubRunId !== void 0 ? _githubRunId : "githubRunId",
        stageName: (_stageName = fallback.stageName) !== null && _stageName !== void 0 ? _stageName : "development"
    };
};
export var registerRumContext = function(buildId) {
    var fallback = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
    var ref = parseBuildId(buildId, fallback), commitSha = ref.commitSha, githubRunId = ref.githubRunId, stageName = ref.stageName;
    datadogRum.setGlobalContextProperty("BUILD_ID", buildId);
    datadogRum.setGlobalContextProperty("STAGE_NAME", stageName);
    datadogRum.setGlobalContextProperty("GITHUB_WORKFLOW", "https://github.com/cinch-labs/cinch-nx/actions/runs/".concat(githubRunId));
    datadogRum.setGlobalContextProperty("COMMIT_DETAILS", "https://github.com/cinch-labs/cinch-nx/commit/".concat(commitSha));
    datadogLogs.setGlobalContextProperty("BUILD_ID", buildId);
};
