import { isBrowser } from "@cinch-labs/shared-util";
export var getDataLayer = function() {
    if (isBrowser) {
        var dl = window.adobeData;
        if (dl) {
            return dl;
        }
    }
    return;
};
export var getTrackingTargetMeta = function() {
    if (isBrowser) {
        var ttMETA = window.ttMETA;
        return ttMETA !== null && ttMETA !== void 0 ? ttMETA : [];
    }
    return;
};
export var updateCompareInformation = function(data) {
    var dl = getDataLayer();
    if (dl) {
        dl.page.compareInformation = data;
    }
};
// Still used by dealer favouriting
export var updateVehicleInformation = function(data) {
    var dl = getDataLayer();
    if (dl) {
        dl.page.vehicleInformation = data;
    }
};
// Still used by dealer find vehicle
export var updateVehicleFilters = function(data) {
    var dl = getDataLayer();
    if (dl) {
        dl.page.vehicleFilters = data;
    }
};
/**
 * @deprecated
 */ export var updateFindVehiclePanel = function(data) {
    var dl = getDataLayer();
    if (dl) {
        dl.page.pageInfo.findVehiclePanel = data;
    }
};
