import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _instanceof from "@swc/helpers/src/_instanceof.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import { setTag, tagError, traceFunction } from "@core/datadog-server";
import { STORYBLOK_BFF_URL } from "../../util/api";
import { getStoriesUrlParams } from "./schema";
export function getStoriesfn(params) {
    return _getStoriesfn.apply(this, arguments);
}
function _getStoriesfn() {
    _getStoriesfn = _async_to_generator(function(params) {
        var urlParams, url, request, ref, error;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    urlParams = getStoriesUrlParams.parse(params);
                    url = "".concat(STORYBLOK_BFF_URL, "/get-all-stories?").concat(urlParams.toString());
                    _state.label = 1;
                case 1:
                    _state.trys.push([
                        1,
                        3,
                        ,
                        4
                    ]);
                    return [
                        4,
                        fetch(url)
                    ];
                case 2:
                    request = _state.sent();
                    setTag("request.url", url);
                    setTag("request.params", JSON.stringify(Object.fromEntries(urlParams.entries()), null, 2));
                    setTag("request.method", "GET");
                    setTag("request.status", request.status);
                    setTag("request.content-length", (ref = request.headers.get("content-length")) !== null && ref !== void 0 ? ref : "0");
                    if (request.status !== 200) {
                        throw new Error("".concat(url, " responded with non-OK response status ").concat(request.status));
                    }
                    return [
                        2,
                        request.json()
                    ];
                case 3:
                    error = _state.sent();
                    if (typeof error === "string" || _instanceof(error, Error)) {
                        tagError(error);
                    }
                    if (_instanceof(error, Error)) {
                        // eslint-disable-next-line unicorn/prefer-type-error
                        throw new Error("'get-all-stories' endpoint threw an error: ".concat(error.message));
                    }
                    throw new Error("'get-all-stories' endpoint threw an unknown error: ".concat(error));
                case 4:
                    return [
                        2
                    ];
            }
        });
    });
    return _getStoriesfn.apply(this, arguments);
}
export var getStories = traceFunction("storyblok.getStories", getStoriesfn);
