import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import { create } from "zustand";
var useStore = create(function(set) {
    return {
        isPreferencesModalOpen: false,
        setIsPreferencesModalOpen: function(arg) {
            var newState = {
                isPreferencesModalOpen: arg
            };
            set(function() {
                return _object_spread({}, newState);
            });
        }
    };
});
var isPreferencesModalOpenSelector = function(state) {
    return state.isPreferencesModalOpen;
};
var setIsPreferencesModalOpenSelector = function(state) {
    return state.setIsPreferencesModalOpen;
};
var useCookieModalOpen = function() {
    var isPreferencesModalOpen = useStore(isPreferencesModalOpenSelector);
    var setIsPreferencesModalOpen = useStore(setIsPreferencesModalOpenSelector);
    return {
        isPreferencesModalOpen: isPreferencesModalOpen,
        setIsPreferencesModalOpen: setIsPreferencesModalOpen
    };
};
export default useCookieModalOpen;
