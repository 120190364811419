import { useEffect, useRef } from "react";
export var useExpiry = function(activeOrderExpiry, setShouldShowExpiringOrExpired, timeNow, warningTime, isExpiryingOrExpired) {
    var isExpiryingOrExpiredRef = useRef(isExpiryingOrExpired);
    useEffect(function() {
        if (activeOrderExpiry) {
            var checkOrderStatus = activeOrderExpiry - warningTime;
            var timeToTriggerRefresh = checkOrderStatus - timeNow;
            var triggerRefresh = timeToTriggerRefresh > 0 ? timeToTriggerRefresh : 0;
            /* This will fire 5 minutes before the basket session runs out */ var timeoutId = setTimeout(function() {
                setShouldShowExpiringOrExpired(isExpiryingOrExpiredRef.current);
            }, triggerRefresh);
            return function() {
                return clearTimeout(timeoutId);
            };
        }
        return;
    }, [
        activeOrderExpiry,
        warningTime,
        timeNow
    ]);
};
