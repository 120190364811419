import { ZendeskMessengerKey } from "./zendesk-messenger.types";
export var getZendeskScriptUrl = function(env) {
    var key = env === "production" ? ZendeskMessengerKey.production : ZendeskMessengerKey.development;
    return "https://static.zdassets.com/ekr/snippet.js?key=".concat(key);
};
export var zendeskMessengerScriptProps = function(param) {
    var _id = param.id, id = _id === void 0 ? "ze-snippet" : _id, _env = param.env, env = _env === void 0 ? "localhost" : _env;
    return {
        id: id,
        src: getZendeskScriptUrl(env),
        "data-testid": id
    };
};
