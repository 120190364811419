import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import { useReservationStore } from "@cinch-labs/data-reservations";
import { sendCheckoutError } from "./helpers/send-checkout-error";
export var handleExtendReservation = function() {
    var _ref = _async_to_generator(function(param) {
        var orderId, token, orderServiceUrl, legacyExtendReservation, res, error;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    orderId = param.orderId, token = param.token, orderServiceUrl = param.orderServiceUrl;
                    legacyExtendReservation = useReservationStore.getState().legacyExtendReservation;
                    _state.label = 1;
                case 1:
                    _state.trys.push([
                        1,
                        3,
                        ,
                        4
                    ]);
                    return [
                        4,
                        legacyExtendReservation(orderId, orderServiceUrl, token)
                    ];
                case 2:
                    res = _state.sent();
                    return [
                        2,
                        res
                    ];
                case 3:
                    error = _state.sent();
                    sendCheckoutError("Error extending reservation (extendOrderExpiryDate)", error, orderId);
                    return [
                        2,
                        undefined
                    ];
                case 4:
                    return [
                        2
                    ];
            }
        });
    });
    return function handleExtendReservation(_) {
        return _ref.apply(this, arguments);
    };
}();
