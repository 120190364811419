export var preserveReferrerAndCID = function() {
    var isBrowser = "object" !== "undefined";
    if (isBrowser) {
        var sessionStorage = window.sessionStorage;
        var savedCID = sessionStorage.getItem("CID");
        if (!savedCID) {
            var urlParams = new URLSearchParams(window.location.search);
            var ref;
            var cid = (ref = urlParams.get("CID")) !== null && ref !== void 0 ? ref : urlParams.get("cid");
            sessionStorage.setItem("CID", cid ? cid : "");
        }
        var savedReferrer = sessionStorage.getItem("referrer");
        if (!savedReferrer) {
            var referrer = document.referrer;
            if (referrer.indexOf("".concat(window.location.protocol, "//").concat(window.location.host)) !== 0) {
                sessionStorage.setItem("referrer", referrer ? referrer : "");
            }
        }
    }
};
