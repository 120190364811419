import { useCallback, useEffect, useState } from "react";
var defaultOptions = {
    updateOnResize: true,
    updateOnOrientationChange: true
};
export var useViewport = function() {
    var options = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : defaultOptions;
    var ref = useState(0), vw = ref[0], setVW = ref[1];
    var ref1 = useState(0), vh = ref1[0], setVH = ref1[1];
    var updateOnResize = options.updateOnResize, updateOnOrientationChange = options.updateOnOrientationChange;
    var setSizes = useCallback(function() {
        if (window.innerWidth !== vw) {
            setVW(window.innerWidth);
        }
        if (window.innerHeight !== vh) {
            setVH(window.innerHeight);
        }
    }, [
        vw,
        vh
    ]);
    useEffect(function() {
        setSizes();
        if (updateOnResize) {
            window.addEventListener("resize", setSizes);
            return function() {
                return window.removeEventListener("resize", setSizes);
            };
        }
        if (updateOnOrientationChange) {
            window.addEventListener("orientationchange", setSizes);
            return function() {
                return window.removeEventListener("orientationchange", setSizes);
            };
        }
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        return function() {};
    }, [
        updateOnResize,
        updateOnOrientationChange,
        vw,
        vh
    ]);
    return {
        vw: vw,
        vh: vh
    };
};
export default useViewport;
