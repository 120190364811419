import _sliced_to_array from "@swc/helpers/src/_sliced_to_array.mjs";
import tracer from "dd-trace";
import { isTraceableEnvironment } from "./is-traceable-environment";
/**
 * Tag a value on the currently active span
 *
 * In the case of array values, this will tag it as a
 * serialised version of the array
 *
 * By default, this will add things below the `context` tag,
 * but if you want to tag something at the root (such as when
 * tagging additional error details) this can be disabled
 * with the optional `config` object
 *
 * On a non-traceable environment, this will do nothing
 */ export var setTag = function(name, value, config) {
    if (!isTraceableEnvironment()) {
        if (!process.env["SUPPRESS_FALLBACK_DATADOG_LOGGING"]) {
            console.debug(">> setTag: [".concat(name, "]: ").concat(value));
        }
        return;
    }
    var ref;
    var nestInContextTag = (ref = config === null || config === void 0 ? void 0 : config.useContextTag) !== null && ref !== void 0 ? ref : true;
    var tagNamePrefix = nestInContextTag ? "context." : "";
    var span = tracer.scope().active();
    if (!span) {
        throw new Error("Active span not available");
    }
    if (typeof value === "object" && value !== null) {
        if (Array.isArray(value)) {
            span.setTag("".concat(tagNamePrefix).concat(name), JSON.stringify(value));
        } else {
            var _iteratorNormalCompletion = true, _didIteratorError = false, _iteratorError = undefined;
            try {
                for(var _iterator = Object.entries(value)[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true){
                    var _value = _sliced_to_array(_step.value, 2), _key = _value[0], _value1 = _value[1];
                    if (typeof _value1 === "object") {
                        setTag("".concat(name, ".").concat(_key), _value1);
                    } else {
                        span.setTag("".concat(tagNamePrefix).concat(name, ".").concat(_key), _value1);
                    }
                }
            } catch (err) {
                _didIteratorError = true;
                _iteratorError = err;
            } finally{
                try {
                    if (!_iteratorNormalCompletion && _iterator.return != null) {
                        _iterator.return();
                    }
                } finally{
                    if (_didIteratorError) {
                        throw _iteratorError;
                    }
                }
            }
        }
    } else {
        span.setTag("".concat(tagNamePrefix).concat(name), value);
    }
};
