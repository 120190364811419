import _class_call_check from "@swc/helpers/src/_class_call_check.mjs";
import _define_property from "@swc/helpers/src/_define_property.mjs";
import { groupDestinations } from "../utils/group-destinations";
export var SegmentPreferences = function SegmentPreferences(destinations, setPreferences) {
    "use strict";
    var _this = this;
    _class_call_check(this, SegmentPreferences);
    this.setCategoryPreference = function(selectedCategory, isSelected) {
        var destination = _this.destinations.find(function(x) {
            return x.category === selectedCategory;
        });
        if (destination) {
            _this.setPreferences(_define_property({}, destination.id, isSelected));
        }
    };
    this.getSelectedDestinations = function(preferences) {
        return _this.destinations.filter(function(dest) {
            return preferences[dest.id] === true;
        });
    };
    this.getDestinationsGroupedByCategory = function() {
        return groupDestinations(_this.destinations, "category");
    };
    this.destinations = destinations;
    this.setPreferences = setPreferences;
};
