import { useEffect, useState } from "react";
function usePrefersReducedMotion() {
    var ref = useState(true), prefersReducedMotion = ref[0], setPrefersReducedMotion = ref[1];
    useEffect(function() {
        var mediaQueryList = window.matchMedia("(prefers-reduced-motion: no-preference)");
        setPrefersReducedMotion(!window.matchMedia("(prefers-reduced-motion: no-preference)").matches);
        var listener = function(event) {
            if (event !== null) {
                setPrefersReducedMotion(!event.matches);
            }
        };
        mediaQueryList.addEventListener("change", listener);
        return function() {
            mediaQueryList.removeEventListener("change", listener);
        };
    }, []);
    return prefersReducedMotion;
}
export default usePrefersReducedMotion;
