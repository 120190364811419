import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
export var searchFilterKeys = [
    "bodyType",
    "colour",
    "seats",
    "doors",
    "fromEngineSize",
    "toEngineSize",
    "fuelType",
    "make",
    "model",
    "trim",
    "mileage",
    "fromPrice",
    "toPrice",
    "gearbox",
    "fromYear",
    "toYear",
    "driveType",
    "store",
    "tags",
    "features", 
];
export var validUrlSegmentFilterKeys = [
    "bodyType",
    "colour",
    "seats",
    "doors",
    "fuelType",
    "make",
    "model",
    "trim",
    "gearbox",
    "driveType", 
];
export var nonFilterParamKeys = [
    "sortingCriteria",
    "pageSize",
    "pageNumber",
    "useMonthly", 
];
export var searchParamKeys = _to_consumable_array(searchFilterKeys).concat(_to_consumable_array(nonFilterParamKeys));
export var defaultFilters = {
    make: [],
    model: [],
    trim: [],
    bodyType: [],
    colour: [],
    seats: [],
    doors: [],
    fromEngineSize: [],
    toEngineSize: [],
    fuelType: [],
    mileage: [],
    fromPrice: [],
    toPrice: [],
    gearbox: [],
    fromYear: [],
    toYear: [],
    driveType: [],
    store: [],
    tags: [],
    features: []
};
export var DEFAULT_PAGE_SIZE = 32;
export var DEFAULT_SORTING_CRITERIA = 3;
export var defaultNonFilterParams = {
    sortingCriteria: DEFAULT_SORTING_CRITERIA,
    pageNumber: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    useMonthly: false
};
