import _sliced_to_array from "@swc/helpers/src/_sliced_to_array.mjs";
import { z } from "zod";
var carReviewsV2WithMake = z.custom(function(val) {
    if (!isString(val) || !val.startsWith("car-reviews-v2/") || val.replace("car-reviews-v2/", "") === "") {
        return false;
    }
    return true;
});
var carReviewsV2WithMakeModel = z.custom(function(val) {
    if (!isString(val)) {
        return false;
    }
    var ref = _sliced_to_array(val.split("/"), 3), prefix = ref[0], make = ref[1], model = ref[2];
    if (prefix !== "car-reviews-v2" || !make || !model) {
        return false;
    }
    return true;
});
var isString = function(input) {
    return typeof input === "string";
};
export var acceptedLinkStarts = z.union([
    z.literal("categoriespages/makes"),
    z.literal("categoriespages/models"),
    z.literal("car-reviews/"),
    z.literal("car-reviews-v2/"),
    carReviewsV2WithMake,
    carReviewsV2WithMakeModel,
    z.literal("jargon"),
    z.literal("articles/guides/ask-the-experts"),
    z.literal("articles/guides/car-finance"),
    z.literal("articles/guides/car-maintenance"),
    z.literal("articles/guides/choosing-a-car"),
    z.literal("articles/guides/electric-cars"),
    z.literal("articles/news"),
    z.literal("articles/announcements/cinch-news"),
    z.literal("articles/hubs"),
    z.literal("seo-pages/electric-cars"),
    z.literal("seo-pages/car-finance"),
    z.literal("seo-pages/offers"),
    z.literal("seo-pages/car-inspection/sub-pages"),
    z.literal("seo-pages/car-inspection/index"),
    z.literal("seo-pages/estate-cars/sub-pages"),
    z.literal("seo-pages/estate-cars/index"),
    z.literal("policies"),
    z.literal("policies/modern-slavery-statement"),
    z.literal("policies/modern-slavery-statement/statements"),
    z.literal("car-care"),
    z.literal("search"), 
]).optional();
