export var Actions;
(function(Actions) {
    Actions["VEHICLE_CARD_CLICKED"] = "Vehicle Card Clicked";
    Actions["SEARCH_ALERTS_CTA_CLICKED"] = "Search alerts - CTA clicked - Open drawer";
    Actions["SEARCH_ALERTS_FORM_SUBMIT"] = "Search alerts - Form submitted";
    Actions["SEARCH_ALERTS_CTA_CLICKED_NO_RESULTS"] = "Search alerts - CTA clicked - No results open drawer";
    Actions["SEARCH_ZERO_VEHICLE_RESULTS"] = "Search - No vehicles returned";
    Actions["COMPARE_BAR_CTA_CLICKED"] = "Compare - Bar CTA clicked";
    Actions["COMPARE_VEHICLES_CTA_VEHICLE_ADDED"] = "Compare - Vehicles CTA vehicle added";
    Actions["COMPARE_VEHICLES_CTA_VEHICLE_REMOVED"] = "Compare - Vehicles CTA vehicle removed";
    Actions["COMPARE_SHOWING_MODAL"] = "Compare - Showing max vehicles modal";
    Actions["COMPARE_BAR_VEHICLE_REMOVED"] = "Compare - Compare Bar vehicle removed";
    Actions["COMPARE_BAR_LOAD_FROM_LOCAL_STORAGE"] = "Compare - Vehicles loaded from localStorage";
    Actions["SEARCH_PART_EX_CTA_CLICKED"] = "Search - Part Ex - CTA clicked";
    Actions["SEARCH_PART_EX_EDIT_VALUATION_CLICKED"] = "Search - Part Ex - Edit valuation clicked";
    Actions["SEARCH_FPA_VEHICLE_STALE_CACHE_DATA"] = "Search - Vehicle card showing stale cached data";
    Actions["SEARCH_SEO_POPULAR_LINKS"] = "Search - SEO - Popular links";
    Actions["SEARCH_FAQ_STORYBLOK_CLICK"] = "Search - Faq storyblok click";
    Actions["READ_MORE_SHOW_LESS_TOGGLE"] = "Read more / Show less toggle";
    Actions["SEARCH_FILTER_SELECTED"] = "Search - Filter selected";
    Actions["SEARCH_OUTRO_CONTENT"] = "Search - Buying guide";
    Actions["SEARCH_VALUE_PROPOSITIONS"] = "Search - Value propositions";
    Actions["SEARCH_FREE_TEXT_SEARCH"] = "Search - Free text search";
    Actions["SEARCH_RECENTLY_VIEWED_VEHICLES"] = "Search - Recently viewed vehicles";
    Actions["SEARCH_BEFORE_POPSTATE_NAVIGATION"] = "Search - Before popstate navigation";
    Actions["SEARCH_ALTERNATIVE_VEHICLES_CLICKED"] = "Search - Alternative vehicles clicked";
    Actions["CINCHAPP_POST_MESSAGE"] = "cinchapp: Webview postMessage sent";
    Actions["CLICK_MULTIPLE_NAV_IMAGE_BUTTON"] = "Multiple images - Image nav button clicked";
    Actions["VIEWED_MULTIPLE_VEHICLE_CARD_IMAGE"] = "Multiple images - Vehicle card image viewed";
    Actions["CLICK_MULTIPLE_VEHICLE_CARD_IMAGE"] = "Multiple images - Vehicle card image clicked";
    Actions["SEARCH_STORE_LOCATIONS_SHOW_ALL_CINCH_STORE_CARS_CLICKED"] = "Store locations - Store locations show all cinch store cars clicked";
    Actions["SEARCH_STORE_LOCATIONS_FIND_OUT_MORE_CLICKED"] = "Store locations - Store locations find out more clicked";
    Actions["SEARCH_STORE_LOCATIONS_HOME_DELIVERY_CLICKED"] = "Store locations - Store locations home delivery clicked";
    Actions["SEARCH_STORE_LOCATIONS_COLLECTION_CLICKED"] = "Store locations - Store locations collection clicked";
    Actions["SEARCH_STORE_LOCATIONS_MONEY_BACK_GUARANTEE_CLICKED"] = "Store locations - Store locations money back guarantee clicked";
})(Actions || (Actions = {}));
