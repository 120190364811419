import { useEffect, useRef } from "react";
export var useFocusNavigation = function(selector, activeMenu) {
    var focusableElements = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
    var sharedNavV2 = useRef(null);
    useEffect(function() {
        sharedNavV2.current = document.querySelector(selector);
        if (activeMenu) {
            var ref, ref1;
            document.body.setAttribute("aria-hidden", "true");
            var navFocusableElements = (ref = sharedNavV2.current) === null || ref === void 0 ? void 0 : ref.querySelectorAll(focusableElements);
            var firstNavElement = (ref1 = sharedNavV2.current) === null || ref1 === void 0 ? void 0 : ref1.querySelectorAll(focusableElements)[0];
            var lastNavElement = navFocusableElements === null || navFocusableElements === void 0 ? void 0 : navFocusableElements[navFocusableElements.length - 1];
            var handleFocusNavigation = function(e) {
                var isTabPressed = e.key === "Tab";
                if (!isTabPressed) {
                    return;
                }
                if (e.shiftKey) {
                    if (document.activeElement === firstNavElement) {
                        lastNavElement.focus();
                        e.preventDefault();
                    }
                } else {
                    if (document.activeElement === lastNavElement) {
                        firstNavElement.focus();
                        e.preventDefault();
                    }
                }
            };
            document.addEventListener("keydown", handleFocusNavigation);
            return function() {
                document.removeEventListener("keydown", handleFocusNavigation);
            };
        }
        document.body.removeAttribute("aria-hidden");
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        return function() {} // noop when the activeMenu is not available
        ;
    }, [
        activeMenu
    ]);
    return sharedNavV2;
};
