import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import { create } from "zustand";
import axios from "axios";
var createAxiosInstance = function(token) {
    return axios.create({
        headers: {
            Authorization: "Bearer ".concat(token)
        }
    });
};
/**
 * @description
 * Used by **both** `front-ends` and `cinch-nx` to manage reservations.
 * Since axios instances are handled slightly differently in each monorepo, we pass in the instance as a parameter.
 * This instance needs to be set up with the correct headers using `interceptors.request.use`
 * (https://axios-http.com/docs/interceptors).
 *
 * @example
 * const { expiresAt, getReservation, extendReservation } = useReservationStore()
 *
 * const expiresAt = await getReservation(orderId, axiosInstance)
 */ export var useReservationStore = create(function(set, get) {
    return {
        legacyExtendReservation: function() {
            var _ref = _async_to_generator(function(orderId, orderServiceUrl, token) {
                var axiosInstance, response;
                return _ts_generator(this, function(_state) {
                    switch(_state.label){
                        case 0:
                            axiosInstance = createAxiosInstance(token);
                            return [
                                4,
                                axiosInstance.post("".concat(orderServiceUrl, "/order/extend-expiry"), {
                                    orderId: orderId
                                })
                            ];
                        case 1:
                            response = _state.sent();
                            return [
                                2,
                                response === null || response === void 0 ? void 0 : response.data
                            ];
                    }
                });
            });
            return function(orderId, orderServiceUrl, token) {
                return _ref.apply(this, arguments);
            };
        }()
    };
});
