import { getCookieBannerMetrics, getCookieSettings } from "./cookies";
import * as TrackInteraction from "./track-interaction";
export var addEvent = function(param) {
    var startTime = param.startTime, elementType = param.elementType, count = param.count, cookieEditorTime = param.cookieEditorTime, cookiePreferences = param.cookiePreferences, settingsType = param.settingsType;
    window.analytics && window.analytics.ready(function() {
        TrackInteraction.cookieSettings(getCookieSettings(startTime, cookiePreferences, settingsType));
        TrackInteraction.cookieBannerMetrics(getCookieBannerMetrics(startTime, elementType, count));
        if (cookieEditorTime !== 0) {
            TrackInteraction.cookieEditorShown(cookieEditorTime);
        }
    });
};
