import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
var expiresAt = Date.now() + 7200000 // 2 Hours
;
var baseOrder = {
    expiresAt: expiresAt,
    id: "12345678-abcd-12ab-a1b2c3d4e5f7",
    userId: "12345678-abcd-12ab-a1b2c3d4e5f7",
    orderRef: "CI-6P8LWN-28778P",
    vehicle: {
        vrm: "YY66SYA",
        priceInPence: 1200000,
        stockType: "cinch",
        imageUrl: "https://eu.cdn.autosonshow.tv/5213/projectcambridge/YY66SYA/06_md.jpg",
        registrationDate: "2016-11-09T00:00:00.000Z",
        model: "XC60",
        derivative: "D4 [190] R DESIGN Nav 5dr Geartronic",
        vin: "YV1DZA8CDH2083943",
        id: "1b8f6fd7-4c25-42c6-9e6b-c202e3533570",
        make: "VOLVO",
        engineCapacityCc: 1969,
        mileage: 59286,
        variant: "D4 [190] R DESIGN Nav 5dr Geartronic"
    },
    amountPayableInPence: 0,
    amountPaidInPence: 123456,
    createdAt: 1621853580333,
    completedAt: 1621853580333,
    customerTitle: "Mr",
    customerFirstName: "Kanye",
    customerLastName: "Test",
    customerEmail: "kanye.test@cinch.co.uk",
    customerContactTelephoneNumber: "012345678910",
    lineItems: [
        {
            priceInPence: 1200000,
            productType: "Vehicle",
            referenceId: "162a92af-c59b-4557-8bcb-1d3fd59165c7"
        }, 
    ],
    orderStatus: "Cancelled",
    orderTotalInPence: 1200000,
    paymentRecords: []
};
export var stubCompletedOrder = _object_spread_props(_object_spread({}, baseOrder), {
    orderStatus: "OrderCompleted"
});
export var stubCancelledOrder = _object_spread_props(_object_spread({}, baseOrder), {
    orderStatus: "Cancelled"
});
export var stubInProgressOrder = _object_spread_props(_object_spread({}, baseOrder), {
    orderStatus: "InProgress"
});
export var stubAwaitingFinanceDepositOrder = _object_spread_props(_object_spread({}, baseOrder), {
    orderStatus: "AwaitingFinanceDeposit",
    financeApplication: {
        id: "12345678-abcd-12ab-a1b2c3d4e5f7",
        advanceAmountInPence: 10000,
        status: "ApplicationApproved"
    }
});
export var stubDeclinedFinanceDepositOrder = _object_spread_props(_object_spread({}, baseOrder), {
    orderStatus: "Cancelled",
    financeApplication: {
        id: "12345678-abcd-12ab-a1b2c3d4e5f7",
        advanceAmountInPence: 10000,
        status: "ApplicationDeclined"
    }
});
export var stubAwaitingFinanceDecisionOrder = _object_spread_props(_object_spread({}, baseOrder), {
    orderStatus: "AwaitingFinanceDecision"
});
export var stubCompletedOrderWithDeliverySlot = _object_spread_props(_object_spread({}, baseOrder), {
    orderStatus: "OrderCompleted",
    deliverySlot: {
        date: "2021-05-30",
        startHour: 10,
        endHour: 12,
        reservationId: "1234-id-456"
    }
});
