var formatter = new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: "GBP",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
});
export var currencyValueFormatter = function(value, isPenceValue) {
    var val;
    if (Number.isNaN(value)) {
        val = 0;
    } else if (isPenceValue) {
        val = value / 100;
    } else {
        val = value;
    }
    return formatter.format(val).replace(".00", "");
};
export var penceToPounds = function(value) {
    return currencyValueFormatter(value, true);
};
export var roundedValueWithoutPence = function(value) {
    return Math.round(value / 100) * 100;
};
export default currencyValueFormatter;
