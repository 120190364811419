import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
/**
 * Generates the URL for the Storyblok image service based on the provided parameters.
 * @param src - The source URL of the image.
 * @param options - The options for generating the image URL.
 * @returns The generated URL for the Storyblok image service.
 * @example
 *
 * const optimisedUrl = getStoryblokImageServiceUrl(
 * 'https://a.storyblok.com/f/39865/2000x1500/3e3e3e3e3e/image.jpg',
 * {
 *   width: 150,
 *   height: 0,
 *   filters: [{ quality: 80 }, { format: 'png' }],
 * })
 *
 */ export function getStoryblokImageServiceUrl(src, options) {
    var ref = options || {}, _width = ref.width, width = _width === void 0 ? 0 : _width, _height = ref.height, height = _height === void 0 ? 0 : _height, _filters = ref.filters, filters = _filters === void 0 ? [] : _filters, _useSmartCrop = ref.useSmartCrop, useSmartCrop = _useSmartCrop === void 0 ? false : _useSmartCrop, fitIn = ref.fitIn, _flipX = ref.flipX, flipX = _flipX === void 0 ? false : _flipX, _flipY = ref.flipY, flipY = _flipY === void 0 ? false : _flipY;
    ensureStoryblokURL(src);
    var url = [
        src,
        "m"
    ] // this enables the optimised image service
    ;
    var parsedFilters = filterToUrlPath(filters);
    if (width && !fitIn) {
        var size = "".concat(flipX ? "-" : "").concat(width);
        size += height ? "x".concat(flipY ? "-" : "").concat(height) : "x".concat(flipY ? "-" : "", "0");
        url.push(size);
    }
    if (!width && height && !fitIn) {
        url.push("".concat(flipX ? "-" : "", "0x").concat(flipY ? "-" : "").concat(height));
    }
    if (useSmartCrop) {
        if (!width || !height) {
            console.warn("cropping requires both width and height.");
        }
        url.push("smart");
    }
    if (fitIn) {
        if (flipX || flipY || useSmartCrop) {
            console.warn("fitIn wont work with smart crop / flipping");
        }
        url.push("fit-in/".concat(fitIn.width, "x").concat(fitIn.height));
    }
    if (parsedFilters) {
        url.push("filters:".concat(parsedFilters));
    }
    return url[url.length - 1] === "m" ? url.join("/") + "/" : url.join("/");
}
/**
 * A simple image loader function that generates the URL for a Storyblok image.
 * @param src - The source URL of the image.
 * @param width - The desired width of the image. Defaults to 0.
 * @param quality - The desired quality of the image. Defaults to 75.
 * @returns The URL of the image with the specified options applied.
 */ export function storyblokImageLoader(param) {
    var src = param.src, _width = param.width, width = _width === void 0 ? 0 : _width, _quality = param.quality, quality = _quality === void 0 ? 75 : _quality;
    return getStoryblokImageServiceUrl(src, {
        width: width,
        filters: [
            {
                quality: quality
            }
        ]
    });
}
/**
 * a more advanced image loader function that allows for more customisation of the image URL.
 * @param options - The options for generating the image loader.
 * @returns The image loader function.
 */ export function generateImageLoader(param) {
    var _widthOverride = param.widthOverride, widthOverride = _widthOverride === void 0 ? 0 : _widthOverride, _height = param.height, height = _height === void 0 ? 0 : _height, _filters = param.filters, filters = _filters === void 0 ? [] : _filters, _useSmartCrop = param.useSmartCrop, useSmartCrop = _useSmartCrop === void 0 ? false : _useSmartCrop;
    return function(param) {
        var src = param.src, _width = param.width, width = _width === void 0 ? 0 : _width, _quality = param.quality, quality = _quality === void 0 ? 75 : _quality;
        return getStoryblokImageServiceUrl(src, {
            width: widthOverride || width,
            height: height,
            filters: [
                {
                    quality: quality
                }
            ].concat(_to_consumable_array(filters)),
            useSmartCrop: useSmartCrop
        });
    };
}
function filterToUrlPath(filters) {
    var data = filters.map(function(filter) {
        if (!filter) {
            return "";
        }
        if ("fill" in filter) {
            return "fill(".concat(filter.fill, ")");
        } else if ("grayscale" in filter) {
            return filter.grayscale ? "grayscale()" : "";
        } else if ("blur" in filter) {
            if (filter.blur.length === 1) {
                return "blur(".concat(minMax(filter.blur[0], 0, 100), ")");
            }
            return "blur(".concat(minMax(filter.blur[0], 0, 100), ",").concat(filter.blur[1], ")");
        } else if ("rotate" in filter) {
            return "rotate(".concat(filter.rotate, ")");
        } else if ("brightness" in filter) {
            return "brightness(".concat(minMax(filter.brightness, 0, 100), ")");
        } else if ("format" in filter) {
            return "format(".concat(filter.format, ")");
        } else if ("focal" in filter) {
            return "focal(".concat(filter.focal[0], "x").concat(filter.focal[1], ":").concat(filter.focal[2], "x").concat(filter.focal[3], ")");
        } else if ("quality" in filter) {
            return "quality(".concat(minMax(filter.quality, 0, 100), ")");
        } else {
            return "";
        }
    }).filter(function(filter) {
        return filter !== "";
    }).join(":");
    return data ? data : "";
}
function minMax(value, min, max) {
    if (value === null || value === undefined || min === null || min === undefined || max === null || max === undefined) {
        throw new Error("minMax: Invalid parameters, expected: value :number, min :number, max :number, received: value ".concat(value, ", min ").concat(min, ", max ").concat(max));
    }
    return Math.min(Math.max(value, min), max);
}
function ensureStoryblokURL(url) {
    var storyblokUrl = new URL(url);
    if (storyblokUrl.host !== "a.storyblok.com") {
        throw new Error("Storyblok Image optimisation only works with storyblok hosted images.");
    }
}
