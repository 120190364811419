import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import { getAnalyticIDs } from "@cinch-labs/shared-util";
var ref, ref1;
export var getSegmentUserId = function() {
    var ref2, ref3;
    return (ref1 = (ref = (ref2 = window.analytics) === null || ref2 === void 0 ? void 0 : ref2.user().id()) !== null && ref !== void 0 ? ref : (ref3 = window.analytics) === null || ref3 === void 0 ? void 0 : ref3.user().anonymousId()) !== null && ref1 !== void 0 ? ref1 : "";
};
export var datadogMiddleware = function(param) {
    var payload = param.payload, next = param.next;
    if (window._DATADOG_SYNTHETICS_BROWSER) return;
    var userId = getSegmentUserId();
    datadogRum.setGlobalContextProperty("user", {
        id: userId
    });
    next(payload);
};
export var excludedPagesMiddleware = function(param) {
    var payload = param.payload, next = param.next;
    var excludedPages = [
        "/auth-callback",
        "/silent-renew"
    ];
    if (excludedPages.includes(payload.obj.context.page.path)) return;
    next(payload);
};
export var unauthenticatedMiddleware = function(param) {
    var payload = param.payload, next = param.next;
    var ref;
    // Have to access this via window.analytics rather than payload because
    // the payload doesn't detail if a user is authed or not
    if ((ref = window.analytics) === null || ref === void 0 ? void 0 : ref.user().id()) return next(payload);
    var integrationWithPermissions = {
        Zendesk: false,
        "Customer.io": false
    };
    var updatedPayload = _object_spread_props(_object_spread({}, payload), {
        obj: _object_spread_props(_object_spread({}, payload.obj), {
            integrations: _object_spread({}, integrationWithPermissions)
        })
    });
    next(updatedPayload);
};
export var configureMiddleware = function() {
    var ref, ref1, ref2;
    (ref = window.analytics) === null || ref === void 0 ? void 0 : ref.addSourceMiddleware(datadogMiddleware);
    (ref1 = window.analytics) === null || ref1 === void 0 ? void 0 : ref1.addSourceMiddleware(excludedPagesMiddleware);
    (ref2 = window.analytics) === null || ref2 === void 0 ? void 0 : ref2.addSourceMiddleware(unauthenticatedMiddleware);
};
export var identify = function(param) {
    var userId = param.userId, data = param.data;
    try {
        if (userId) {
            var ref;
            (ref = window.analytics) === null || ref === void 0 ? void 0 : ref.identify(userId, _object_spread({}, data));
        } else {
            var ref1;
            (ref1 = window.analytics) === null || ref1 === void 0 ? void 0 : ref1.identify(_object_spread({}, data));
        }
    } catch (error) {
        datadogLogs.logger.error("call to window.analytics.identify() failed", {
            userId: userId,
            data: data,
            error: error
        });
    }
};
export var track = function(eventName, data) {
    if (!eventName || eventName.length === 0) return;
    var ref = getAnalyticIDs(), adobeID = ref.adobeID, gaID = ref.gaID;
    var analyticsContext = {
        analyticIds: {
            adobeID: adobeID,
            gaID: gaID
        }
    };
    var options = {
        context: analyticsContext
    };
    try {
        var ref1;
        (ref1 = window.analytics) === null || ref1 === void 0 ? void 0 : ref1.track(eventName, data !== null && data !== void 0 ? data : {}, options);
    } catch (error) {
        datadogLogs.logger.error("call to window.analytics.track() failed", {
            eventName: eventName,
            data: data,
            error: error
        });
    }
};
export var reset = function() {
    try {
        var ref;
        (ref = window.analytics) === null || ref === void 0 ? void 0 : ref.reset();
    } catch (e) {
        datadogLogs.logger.error("call to window.analytics.reset() failed");
    }
};
export var page = function(name, data) {
    if (!name) return;
    try {
        var ref;
        (ref = window.analytics) === null || ref === void 0 ? void 0 : ref.page(name, data !== null && data !== void 0 ? data : {}, {
            integrations: {
                Zendesk: false
            }
        });
    } catch (error) {
        datadogLogs.logger.error("call to window.analytics.page() failed", {
            name: name,
            data: data,
            error: error
        });
    }
};
