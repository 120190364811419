import { getCookie, setCookie } from "./cookie";
export var COOKIE_CONSENT_PREFERENCES_KEY = "cp";
var cookiePreferencesCookieParser = function(cookieContents) {
    return cookieContents ? JSON.parse(cookieContents) : undefined;
};
var getCookiePreferencesFromUrl = function() {
    if (!window) {
        return;
    }
    try {
        var url = new URL(window.location.href);
        var preferences = url.searchParams.get(COOKIE_CONSENT_PREFERENCES_KEY);
        url.searchParams.delete(COOKIE_CONSENT_PREFERENCES_KEY);
        window.history.replaceState(window.history.state, "unused", url.toString());
        return cookiePreferencesCookieParser(preferences);
    } catch (e) {
        return;
    }
};
export var getCookieConsentPreferences = function() {
    var cookiePreferencesFromUrl = getCookiePreferencesFromUrl();
    if (cookiePreferencesFromUrl) {
        setCookieConsentPreferences(cookiePreferencesFromUrl);
        return cookiePreferencesFromUrl;
    }
    return cookiePreferencesCookieParser(getCookie({
        name: COOKIE_CONSENT_PREFERENCES_KEY
    }));
};
export var hasCookieConsentBeenSet = function() {
    return getCookieConsentPreferences() ? true : false;
};
export var setCookieConsentPreferences = function(preferences) {
    return setCookie({
        name: COOKIE_CONSENT_PREFERENCES_KEY,
        value: JSON.stringify(preferences),
        expiryInDays: 365
    });
};
export var getCookieSettings = function(time, preferences, type) {
    return {
        preference_functional: preferences["functional"] ? true : false,
        preference_advertising: preferences["advertising"] ? true : false,
        preference_marketing: preferences["analytics"] ? true : false,
        type: type,
        time: Math.round(Date.now() / 1000 - time / 1000)
    };
};
export var getCookieBannerMetrics = function(time, mode, count) {
    return {
        display_duration: Math.round(Date.now() / 1000 - time / 1000),
        acceptance_mode: mode,
        navigation_count: count,
        event_timing: Math.round(Date.now() / 1000 - time / 1000)
    };
};
