import data from "./car-data";
export var getMake = function(make) {
    if (!make) {
        return "";
    }
    try {
        return data[make.trim()].displayName;
    } catch (e) {
        return make;
    }
};
export var getModel = function(make, model) {
    if (!make || !model) {
        return "";
    }
    if (data[make.trim()] && data[make.trim()].models && data[make.trim()].models[model.trim()]) {
        return data[make.trim()].models[model.trim()];
    }
    // make and model is not found!
    // todo track an event for this!
    return model;
};
