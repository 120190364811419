import { z } from "zod";
import { cv } from "../../types/schema";
export var getDatasourceEntriesParams = z.object({
    cv: cv,
    datasource: z.string(),
    dimension: z.string().optional()
});
export var datasourceEntry = z.object({
    id: z.number(),
    name: z.string(),
    value: z.string(),
    dimension_value: z.string().nullable()
});
export var getDatasourceEntriesResponse = z.object({
    datasource_entries: z.array(datasourceEntry)
});
