import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import customEventNames from "./custom-event-names";
import { getDataLayer } from "./data-layer";
import trackCustomEvent from "./track-custom-event";
var vehicleAction;
(function(vehicleAction) {
    vehicleAction["seenInitial"] = "seenInitial";
    vehicleAction["seenMore"] = "seenMore";
    vehicleAction["notSeen"] = "notSeen";
})(vehicleAction || (vehicleAction = {}));
export var isInitialPageLoad = true;
export var getVehicleReturned = function() {
    var dl = getDataLayer();
    if (!dl) {
        return;
    }
    if (dl && !dl.page.vehicleReturned) {
        dl.page.vehicleReturned = new Map();
    }
    return dl.page.vehicleReturned;
};
export var trackNewVehicles = function() {
    var vehicles = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : [];
    var dl = getVehicleReturned();
    if (!dl) {
        return;
    }
    dl.clear();
    var mappedVehicles = vehicles.map(function(vehicle) {
        return {
            advertId: typeof vehicle.vehicleId === "number" ? vehicle.vehicleId.toString() : vehicle.vehicleId,
            dealerId: vehicle.webDealerProfileId,
            imageCount: vehicle.imageCount,
            price: typeof vehicle.price === "number" ? vehicle.price : Number.parseInt(vehicle.price.replace(/\D+/g, ""), 10)
        };
    });
    var _iteratorNormalCompletion = true, _didIteratorError = false, _iteratorError = undefined;
    try {
        for(var _iterator = mappedVehicles[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true){
            var vehicle = _step.value;
            dl.set(vehicle.advertId, _object_spread_props(_object_spread({}, vehicle), {
                action: vehicleAction.notSeen
            }));
        }
    } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
    } finally{
        try {
            if (!_iteratorNormalCompletion && _iterator.return != null) {
                _iterator.return();
            }
        } finally{
            if (_didIteratorError) {
                throw _iteratorError;
            }
        }
    }
    trackCustomEvent({
        name: customEventNames.vehicleListingLoadSuccess,
        details: mappedVehicles
    });
};
export var resetProductDisplay = function() {
    var dl = getVehicleReturned();
    if (!dl) {
        return;
    }
    isInitialPageLoad = true;
    dl.clear();
};
export var trackVehicleDisplay = function(advertId, isDisplayed, isInitialLoad) {
    var dl = getVehicleReturned();
    if (!dl) {
        return;
    }
    var vehicle = dl.get(advertId);
    if (vehicle && isDisplayed) {
        var action = vehicle.action === vehicleAction.seenInitial || isInitialLoad ? vehicleAction.seenInitial : vehicleAction.seenMore;
        dl.set(advertId, _object_spread_props(_object_spread({}, vehicle), {
            action: action
        }));
    }
};
