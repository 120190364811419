import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { EventActions, EventCategories, EventNames } from "./return-to-checkout-modal.types";
import { TrackingEventTypes } from "./types/analytics";
export var useReturnToCheckoutModalTracking = function(trackEvent) {
    var resumeCheckoutOpenedModal = function(section) {
        var evt = {
            event: {
                name: EventNames.click,
                category: EventCategories.button,
                action: EventActions.modalOpened,
                label: "checkout-resume",
                section: section
            }
        };
        trackEvent({
            eventName: "Return to Checkout - Modal Opened",
            data: _object_spread_props(_object_spread({}, evt), {
                modalState: section
            }),
            type: TrackingEventTypes.ADOBE
        });
    };
    var resumeCheckoutClosedModal = function(section) {
        var evt = {
            event: {
                name: EventNames.click,
                category: EventCategories.button,
                action: EventActions.modalClosed,
                label: "checkout-resume",
                section: section
            }
        };
        trackEvent({
            eventName: "Return to Checkout - Modal Closed",
            data: _object_spread_props(_object_spread({}, evt), {
                modalState: section
            }),
            type: TrackingEventTypes.ADOBE
        });
    };
    var resumeCheckoutReturnedToCheckout = function(section) {
        var evt = {
            event: {
                name: EventNames.click,
                category: EventCategories.button,
                action: EventActions.returnToCheckout,
                label: "checkout-resume",
                section: section
            }
        };
        trackEvent({
            eventName: "Return to Checkout - User Clicked Modal CTA",
            data: _object_spread_props(_object_spread({}, evt), {
                modalState: section
            }),
            type: TrackingEventTypes.ADOBE
        });
    };
    var clickModalVehicleImage = function() {
        var evt = {
            event: {
                name: EventNames.click,
                category: EventCategories.image,
                action: EventActions.deadClick,
                label: "checkout-return-car-image"
            }
        };
        trackEvent({
            eventName: "Return to Checkout - User Clicked Vehicle Image",
            data: _object_spread({}, evt),
            type: TrackingEventTypes.ADOBE
        });
    };
    var iDoNotWantThisCarEvent = function() {
        var evt = {
            event: {
                name: EventNames.click,
                category: EventCategories.button,
                action: EventActions.iDoNotWantThisCar,
                label: "checkout-resume-modal"
            }
        };
        trackEvent({
            eventName: "Return to Checkout modal - User Clicked I do not want this car",
            data: _object_spread({}, evt),
            type: TrackingEventTypes.ADOBE
        });
    };
    var returnToCheckoutModalEvent = function() {
        var evt = {
            event: {
                name: EventNames.click,
                category: EventCategories.button,
                action: EventActions.returnToCheckout,
                label: "checkout-resume-modal"
            }
        };
        trackEvent({
            eventName: "Return to Checkout modal - User Clicked Return to Checkout",
            data: _object_spread({}, evt),
            type: TrackingEventTypes.ADOBE
        });
    };
    return {
        resumeCheckoutOpenedModal: resumeCheckoutOpenedModal,
        resumeCheckoutClosedModal: resumeCheckoutClosedModal,
        resumeCheckoutReturnedToCheckout: resumeCheckoutReturnedToCheckout,
        clickModalVehicleImage: clickModalVehicleImage,
        returnToCheckoutModalEvent: returnToCheckoutModalEvent,
        iDoNotWantThisCarEvent: iDoNotWantThisCarEvent
    };
};
