export function checkOrderHasExpired(activeOrder, callback) {
    if (!activeOrder) {
        return;
    }
    var _expiresAt;
    var activeOrderExpiresCancelsAt = (_expiresAt = activeOrder.expiresAt) !== null && _expiresAt !== void 0 ? _expiresAt : activeOrder.cancelledAt;
    if (!activeOrderExpiresCancelsAt) {
        return;
    }
    var timeDifference = activeOrderExpiresCancelsAt - Date.now();
    if (timeDifference <= 0) {
        callback();
        return;
    }
    setTimeout(callback, timeDifference);
}
