import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import tracer from "dd-trace";
import { isTraceableEnvironment } from "./is-traceable-environment";
/**
 * Create a span that will last for the duration of the supplied callback
 *
 * Unlike `traceFunction`, this calls the supplied function immediately.
 *
 * On a non-traceable environment, this will just call the supplied function
 */ export var startSpan = function() {
    var _ref = _async_to_generator(function(key, callback) {
        return _ts_generator(this, function(_state) {
            return [
                2,
                isTraceableEnvironment() ? tracer.trace(key, callback) : callback()
            ];
        });
    });
    return function startSpan(key, callback) {
        return _ref.apply(this, arguments);
    };
}();
