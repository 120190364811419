import { Env, readFromEnv } from "@cinch-nx/environments";
import axios from "axios";
import useSWR from "swr";
export var VEHICLE_SEARCH_URL = "".concat(readFromEnv(Env.SearchServiceUrl), "/vehicles");
var fetcher = function(url) {
    return axios.get(url).then(function(res) {
        return res.data;
    });
};
export var useLatestVehicles = function(param) {
    var make = param.make, model = param.model, caller = param.caller, tags = param.tags, fuelType = param.fuelType, bodyType = param.bodyType, seats = param.seats, colour = param.colour, numberOfVehicles = param.numberOfVehicles, _count = param.count, count = _count === void 0 ? !numberOfVehicles ? 3 : numberOfVehicles : _count;
    var queryObject = {
        sortingCriteria: "13"
    };
    var browseButtonUrl = "/used-cars";
    if (caller === "seo-pages") {
        var searchFilter = {
            tags: tags,
            fuelType: fuelType,
            bodyType: bodyType,
            seats: seats,
            colour: colour
        };
        for(var key in searchFilter){
            if (Object.prototype.hasOwnProperty.call(searchFilter, key) && searchFilter[key] && searchFilter[key] !== "") {
                var filter = key;
                var filterValue = searchFilter[key];
                queryObject[filter] = filterValue;
                browseButtonUrl = "/used-cars?".concat(filter, "=").concat(filterValue);
                break;
            }
        }
    } else {
        queryObject["make"] = make !== null && make !== void 0 ? make : "";
        if (model) {
            queryObject["selectedModel"] = model;
        }
        if (fuelType) {
            queryObject["fuelType"] = fuelType;
        }
    }
    var qs = new URLSearchParams(queryObject);
    var url = "".concat(VEHICLE_SEARCH_URL, "?").concat(qs.toString());
    var ref = useSWR(url, fetcher), data = ref.data, error = ref.error;
    var countOfData = data === null || data === void 0 ? void 0 : data.searchResultsCount;
    data === null || data === void 0 ? void 0 : data.vehicleListings.splice(count);
    return {
        data: data,
        countOfData: countOfData,
        browseButtonUrl: browseButtonUrl,
        error: error
    };
};
