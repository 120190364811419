import { formatEngineSizeInLitres, formatFuelType } from "./format-vehicle-data";
var sentenceCase = function(input) {
    return input.charAt(0).toUpperCase() + input.slice(1).toLowerCase();
};
export var getVehicleTags = function(vehicle) {
    return [
        {
            label: "Body Type",
            value: vehicle.bodyType
        },
        {
            label: "Colour",
            value: sentenceCase(vehicle.colour)
        },
        {
            label: "Engine Size",
            value: formatEngineSizeInLitres(vehicle.engineSize || vehicle.engineCapacityCc)
        },
        {
            label: "Fuel Type",
            value: formatFuelType(vehicle.fuelType)
        },
        {
            label: "Mileage",
            value: "".concat(Intl.NumberFormat().format(vehicle.mileage), " miles")
        },
        {
            label: "Transmission Type",
            value: sentenceCase(vehicle.transmissionType)
        },
        {
            label: "Vehicle Year",
            value: "".concat(vehicle.vehicleYear)
        },
        {
            label: "Doors",
            value: "".concat(vehicle.doors, " Doors")
        },
        {
            label: "Seats",
            value: "".concat(vehicle.seats, " Seats")
        }, 
    ].filter(function(param) {
        var value = param.value;
        return value !== "-";
    }).filter(function(param) {
        var value = param.value;
        return !!value;
    });
};
