export var groupDestinations = function(group, prop) {
    return(// eslint-disable-next-line unicorn/prefer-object-from-entries
    group.reduce(function(grouped, item) {
        var key = item[prop];
        if (!Object.prototype.hasOwnProperty.call(grouped, prop)) {
            grouped[key] = [];
        }
        grouped[key].push(item);
        return grouped;
    }, {}));
};
